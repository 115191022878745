import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    ContentState,
    createWithContent,
    convertToRaw,
    convertFromRaw,
    convertFromHtml,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import  '../editor.css';

export function Descriptions(props) {
    const {
        classes,
        history,
        topDescription,
        setTopDescription,
        longDescription,
        setLongDescription
    } = props;
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Short Description</h5>
                        </GridItem>
                        <GridItem xs={12}>
                            <div style={{ border: "2px solid #56CA93"}}>
                                <Editor
                                    editorState={topDescription}
                                    onEditorStateChange={(state) => setTopDescription(state)}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                </Card>

                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Long Description</h5>
                        </GridItem>
                        <GridItem xs={12}>
                            <div style={{ border: "2px solid #56CA93" }}>
                                <Editor
                                    editorState={longDescription}
                                    onEditorStateChange={(state) => setLongDescription(state)}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Descriptions));