import React, { useState, createRef, useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import moment from "moment"
import Datetime from "react-datetime";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Dialog, Typography, Card, Popper, TextField, InputAdornment } from "@material-ui/core";

// styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { BsDownload } from "react-icons/bs";


export function DateRange(props) {
    const {
        classes,
        fromDate,
        setFromDate,
        toDate,
        setToDate
    } = props

    const tomorrow = moment().add(0, "day");
    const disableFutureDt = (current) => {
        return current.isBefore(tomorrow);
    };

    const disableFromDt = (current) => {
        return current.isAfter(fromDate);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDescBilling} style={{ marginLeft: "0px" }}>
                        From
                    </Typography>
                </GridItem>

                <GridItem xs={6}>
                    <Datetime
                        isValidDate={disableFutureDt}
                        className='travel'
                        selected={fromDate}
                        dateFormat="MM/DD/YY"
                        onChange={(date, e) => {
                            setFromDate(date.format("MM/DD/YY"));
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside
                        renderInput={(props, openCalendar, closeCalendar) => (
                            <div onClick={openCalendar}>
                                <TextField
                                    className={classes.datetime}
                                    value={fromDate}
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            adornedEnd: classes.adournedEnd,
                                            input: classes.input
                                        },
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.adournedEnd}
                                                position="end"
                                            >
                                                <Button className={classes.datetimeButton}>
                                                    <CalendarIcon
                                                        className={classes.calendarIcon}
                                                        onClick={openCalendar}
                                                    />
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    />
                </GridItem>

                <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDescBilling} style={{ marginLeft: "0px" }}>
                        To
                    </Typography>
                </GridItem>

                <GridItem xs={6}>
                    <Datetime
                        isValidDate={disableFromDt}
                        className='travel'
                        selected={toDate}
                        dateFormat="MM/DD/YY"
                        onChange={(date, e) => {
                            setToDate(date.format("MM/DD/YY"));
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside
                        renderInput={(props, openCalendar, closeCalendar) => (
                            <div onClick={openCalendar}>
                                <TextField
                                    className={classes.datetime}
                                    value={toDate}
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            adornedEnd: classes.adournedEnd,
                                            input: classes.input
                                        },
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.adournedEnd}
                                                position="end"
                                            >
                                                <Button className={classes.datetimeButton}>
                                                    <CalendarIcon
                                                        className={classes.calendarIcon}
                                                        onClick={openCalendar}
                                                    />
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    />
                </GridItem>

                <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        className={classes.downloadButton}
                    >
                        <BsDownload style={{ marginRight: "10px" }} /> Download Report
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(DateRange));