import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    ContentState,
    createWithContent,
    convertToRaw,
    convertFromRaw,
    convertFromHtml,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Datetime from "react-datetime";
import moment from "moment";
import { db, firebase, storage } from 'firebase/client.js';
import RewardName from './formComponents/RewardName';
import Description from './formComponents/Descriptions';
import General from './formComponents/General';
import Shipping from './formComponents/Shipping';
import MainImage from './formComponents/MainImage';
import RewardGallery from './formComponents/RewardGallery';
import Categories from './formComponents/Categories';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Dialog, DialogContent, DialogTitle, Hidden, MenuItem, Select, Typography } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { BsCheckCircleFill } from "react-icons/bs"
import { BiSolidLeftArrow } from "react-icons/bi"

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import "./calendar.css"
import loadingWheel from "assets/img/loader.gif";
import searchControlStyle from 'assets/jss/material-dashboard-pro-react/components/searchControlStyle';


const statusArr = [
    "Draft",
    "Active",
    "Schedule",
];

const taxStatusArr = [
    "Taxable",
    "None",
];

const taxClassArr = [
    "Standard",
    "Zero Rate",
]


const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
    return current.isAfter(yesterday);
};

export function NewRewardsForm(props) {
    const { classes, history, user } = props;
    const [same, setSame] = useState(false)
    const [urls, setUrls] = useState([])
    const [status, setStatus] = useState("Draft")
    const [rewardName, setRewardName] = useState("")
    const [topDescription, setTopDescription] = useState(EditorState.createEmpty());
    const [longDescription, setLongDescription] = useState(EditorState.createEmpty());
    const [regularPrice, setRegularPrice] = useState("")
    const [regPriceConvert, setRegPriceConvert] = useState(0)
    const [salePrice, setSalePrice] = useState("")
    const [salePriceConvert, setSalePriceConvert] = useState(0)
    const [sku, setSku] = useState("")
    const [taxStatus, setTaxStatus] = useState("Taxable")
    const [taxClass, setTaxClass] = useState("Standard")
    const [units, setUnits] = useState("")
    const [notes, setNotes] = useState("")
    const [weight, setWeight] = useState("")
    const [length, setLength] = useState("")
    const [width, setWidth] = useState("")
    const [height, setHeight] = useState("")
    const [mainImage, setMainImage] = useState([])
    const [rewardImages, setRewardImages] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [categories, setCategories] = useState(
        {
            luxuryGoods: false,
            travel: false,
            electronics: false,
            experiences: false,
            giftCards: false,
            raffle: false,
        }
    )
    const [openModal, setOpenModal] = useState(false)
    const [scheduleDate, setScheduleDate] = useState("")
    const [time, setTime] = useState("")
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [urlTest, setUrlTest] = useState("")
    useEffect(() => {
        if (status === "Schedule") {
            setOpenModal(true)
            setCalendarOpen(true)
        }
    }, [status])

    const reset = () => {
        setOpenModal(false)
        setCalendarOpen(false)
        setStatus("Draft")
        setScheduleDate("")
        setTime("")
    }

    const handleChecked = (e) => {
        let newObj = {
            luxuryGoods: false,
            travel: false,
            electronics: false,
            experiences: false,
            giftCards: false,
            raffle: false,
        }
        setCategories({
            ...newObj,
            [e.target.value]: e.target.checked
        })
    }

    const ptsCalculatorReg = (pts) => {
        // const netBatchProcessedAmount = pts - .039
        // const dollarPerBasisPoint = netBatchProcessedAmount * .01
        // const totalRewardDollarValue = dollarPerBasisPoint * 20
        // setRegPriceConvert(Math.floor(totalRewardDollarValue))
        setRegPriceConvert(pts * 100)
    }

    const ptsCalculatorSale = (pts) => {
        // const netBatchProcessedAmount = pts - .039
        // const dollarPerBasisPoint = netBatchProcessedAmount * .01
        // const totalRewardDollarValue = dollarPerBasisPoint * 20
        // setSalePriceConvert(Math.floor(totalRewardDollarValue))
        setSalePriceConvert(pts * 100)
    }

    const saveForm = async () => {
        setShowModal(true)
        const docId = db.collection('rewards-list-test').doc().id
        let mainImageUrl = "";

        if (mainImage.length > 0) {
            const file = mainImage[0]
            // const uploadTask = 
            await firebase.storage().ref().child(`rewards-form/${rewardName}/main-image/${file.name}/`).put(file)
                .then(async () => {
                    await firebase
                        .storage()
                        .ref()
                        .child(`rewards-form/${rewardName}/main-image/${file.name}/`)
                        .getDownloadURL()
                        .then(async (url) => {
                            console.log("testing url", url)
                            mainImageUrl = url
                        })
                    return mainImageUrl
                })
        }

        const data = {
            status,
            rewardName,
            topDescription: draftToHtml(
                convertToRaw(topDescription.getCurrentContent())
            ),
            longDescription: draftToHtml(
                convertToRaw(longDescription.getCurrentContent())
            ),
            regularPrice,
            regularPricePoints: regPriceConvert,
            salePrice,
            salePricePoints: salePriceConvert,
            sku,
            taxStatus,
            taxClass,
            notes,
            weight,
            length,
            width,
            height,
            mainImage: mainImageUrl,
            // galleryImages: urls,
            categories,
            scheduleDate,
            time,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            units
        }

        try {
            await db.collection('rewards-list-test').add(data)
                .then(async function (docRef) {
                    db.collection('rewards-list-test').doc(docRef.id).update({
                        rewardId: docRef.id,
                    })
                        .then(() => {
                            if (rewardImages.length > 0) {
                                rewardImages.map((image) => {
                                    const uploadTask = firebase.storage().ref(`rewards-form/${rewardName}/gallery-images/${image.name}`).put(image);
                                    // promises.push(uploadTask);
                                    uploadTask.on(
                                        "state_changed",
                                        (snapshot) => {
                                            const progress = Math.round(
                                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                            );
                                            
                                        },
                                        (error) => {
                                            console.log(error);
                                        },
                                        async () => {
                                            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
                                            
                                            await
                                                db.collection('rewards-list-test').doc(docRef.id).update({
                                                    galleryImages: firebase.firestore.FieldValue.arrayUnion(downloadURL),
                                                })
                                                    .then(() => {
                                                        setShowModal(false)
                                                        setTimeout(() => { window.location.href = ("/admin/rewards") }, 3000);
                                                    })
                                        }
                                    );
                                    setShowModal(false)
                                });
                            }
                            else {
                                setShowModal(false)
                                setTimeout(() => { window.location.href = ("/admin/rewards") }, 1000);
                            }

                        })

                })
        } catch (error) {
            console.log("checking error", error)
        }


    }

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <div>
                    <Typography variant="h2" className={classes.dashboardTitle} style={{ color: "#1A405F" }}>
                        Please View Page on Desktop / Laptop
                    </Typography >
                </div>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer>
                    <GridItem xs={2}>
                        <Typography variant="h2" className={classes.RewardsFormTitle}>
                            Add Reward
                        </Typography>
                    </GridItem>
                    <GridItem xs={3}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Button
                                className={classes.saveButton}
                                style={{
                                    background: "#1A405F",
                                }}
                                onClick={() => saveForm()}
                            >
                                <SaveIcon className={classes.icon} /> Save
                            </Button>
                            <Button
                                className={classes.saveButton}
                            >
                                <ViewIcon className={classes.icon} /> Preview
                            </Button>
                            <Button
                                className={classes.saveButton}
                                style={{
                                    background: "#56CA93",
                                }}
                                onClick={() => history.push('/admin/rewards')}
                            >
                                <TrashIcon className={classes.icon} /> Cancel
                            </Button>
                        </div>
                    </GridItem>
                    <GridItem xs={7} style={{ justifyContent: "flex-end", display: "flex" }}>
                        <div style={{ display: "block" }}>
                            <p style={{
                                fontFamily: "Josefin Sans",
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "#004253",
                                marginBottom: "-5px"
                            }}
                            >
                                Status</p>
                            <Select
                                style={status === "Active" ? { color: "#56CA93" } : {}}
                                className={classes.selectStatus}
                                value={status}
                                disableUnderline
                                classes={{
                                    root: classes.selectText,
                                }}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                {statusArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            value={o}
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            {o}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    </GridItem>
                    <GridItem xs={9}>
                        <RewardName
                            rewardName={rewardName}
                            setRewardName={setRewardName}
                        />
                        <Description
                            topDescription={topDescription}
                            setTopDescription={setTopDescription}
                            longDescription={longDescription}
                            setLongDescription={setLongDescription}
                        />

                        <General
                            regPriceConvert={regPriceConvert}
                            salePriceConvert={salePriceConvert}
                            ptsCalculatorReg={ptsCalculatorReg}
                            ptsCalculatorSale={ptsCalculatorSale}
                            regularPrice={regularPrice}
                            setRegularPrice={setRegularPrice}
                            salePrice={salePrice}
                            setSalePrice={setSalePrice}
                            sku={sku}
                            setSku={setSku}
                            taxStatus={taxStatus}
                            setTaxStatus={setTaxStatus}
                            taxClass={taxClass}
                            setTaxClass={setTaxClass}
                            notes={notes}
                            setNotes={setNotes}
                            taxStatusArr={taxStatusArr}
                            taxClassArr={taxClassArr}
                            units={units}
                            setUnits={setUnits}
                        />

                        <Shipping
                            weight={weight}
                            setWeight={setWeight}
                            length={length}
                            setLength={setLength}
                            width={width}
                            setWidth={setWidth}
                            height={height}
                            setHeight={setHeight}
                        />
                    </GridItem>

                    <GridItem xs={3}>
                        <Categories
                            categories={categories}
                            setCategories={setCategories}
                            handleChecked={handleChecked}
                        />

                        <MainImage
                            mainImage={mainImage}
                            setMainImage={setMainImage}
                        />
                        <RewardGallery
                            rewardImages={rewardImages}
                            setRewardImages={setRewardImages}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>
            <Dialog
                className={classes.scheduleModal}
                open={openModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Schedule</h5>
                        </GridItem>
                        <GridItem xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                            <Datetime
                                className={classes.rdt}
                                open={calendarOpen}
                                isValidDate={disablePastDt}
                                input={false}
                                selected={scheduleDate}
                                dateFormat="MM/DD/YY"
                                // dateFormat={false}
                                onChange={(date, e) => {
                                    setScheduleDate(date);
                                }}
                                timeFormat={false}
                                closeOnSelect={true}
                                closeOnClickOutside
                            />
                        </GridItem>
                        <GridItem xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                            <Datetime
                                placeholder="From"
                                open={calendarOpen}
                                isValidDate={disablePastDt}
                                input={false}
                                selected={time}
                                dateFormat={false}
                                onChange={(time, e) => {
                                    setTime(time);
                                }}
                                timeFormat={true}
                                closeOnSelect={true}
                                closeOnClickOutside
                            />
                        </GridItem>
                    </GridContainer>
                    <div style={{ display: "flex", justifyContent: 'space-evenly', marginBottom: "10px" }}>
                        <Button
                            style={{ background: "#1A405F", width: "50%" }}
                            className={classes.saveButton}
                            onClick={() => setOpenModal(false)}
                        >
                            <BsCheckCircleFill style={{ marginRight: "10px", fontSize: "22px" }} />Schedule
                        </Button>
                        <Button onClick={reset}
                            className={classes.saveButton}
                            style={{ background: "#56CA93", width: "50%" }}
                        >
                            <BiSolidLeftArrow style={{ marginRight: "10px", fontSize: "22px" }} /> Back
                        </Button>
                    </div>
                </Card>
            </Dialog>

            <Dialog
                open={showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold", textAlign: "center" }}>
                    Please wait...
                </DialogTitle>
                <DialogContent id="alert-dialog-description" style={{ textAlign: "center", padding: "10px" }}>
                    <center><img style={{ width: "75px", height: "75px" }} src={loadingWheel} /></center>
                </DialogContent>

            </Dialog>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(NewRewardsForm));