import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import dotenv from 'dotenv';
import "firebase/functions"

// ensure config is read
dotenv.config();

// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

const config = {
  apiKey: "AIzaSyAfx336iEXQID7gZTEaeNhJ9p0LdyJUlkw",
  authDomain: "tuzo-379415.firebaseapp.com",
  projectId: "tuzo-379415",
  storageBucket: "tuzo-379415.appspot.com",
  messagingSenderId: "733086013802",
  appId: "1:733086013802:web:eb2feb53638f8dd6826d13",
  measurementId: "G-NW4355NN49"
};

firebase.initializeApp(config);
firebase.firestore().settings({});

const db = firebase.firestore();
const storage = firebase.storage();
console.log('storage', storage)
const functions = firebase.functions();
// var sendCustomerNotificationEmail = functions.httpsCallable('sendCustomerNotificationEmail');
var sendTempPassword = functions.httpsCallable('sendTempPassword');
var setMerchantInfo = functions.httpsCallable('setMerchantInfo');
var sendCloudFunction = functions.httpsCallable('sendCloudFunction');
var sendIrisCloudFunction = functions.httpsCallable('sendIrisCloudFunction')
var sendSabreFunction = functions.httpsCallable('sendSabreFunction');
var sendSabreCarRental = functions.httpsCallable('sendSabreCarRental');
var sendSignUpLink = functions.httpsCallable('sendSignUpLink');
var sendSignUpLinkLive = functions.httpsCallable('sendSignUpLinkLive')
var sendAdminSignUpLinkLive = functions.httpsCallable('sendAdminSignUpLinkLive')
var sendIsoSignUpLinkLive = functions.httpsCallable('sendIsoSignUpLinkLive')
var sendIsoAdminSignUpLinkLive = functions.httpsCallable('sendIsoAdminSignUpLinkLive')
var sendRequest = functions.httpsCallable('sendRequest')
var sendIrisGetFunction = functions.httpsCallable('sendIrisGetFunction')
var sendReferral = functions.httpsCallable('sendReferral')
var sendAssignReward = functions.httpsCallable('sendAssignReward')
// var sendSMS = functions.httpsCallable('sendSMS');
// var tokenExchange = functions.httpsCallable('tokenExchange');

// var updateToken = functions.httpsCallable('update_token');
export {
  db,
  storage,
  firebase,
  functions,
  // sendCustomerNotificationEmail,
  sendCloudFunction,
  sendIrisCloudFunction,
  sendSabreFunction,
  sendSabreCarRental,
  setMerchantInfo,
  sendTempPassword,
  sendSignUpLink,
  sendRequest,
  sendReferral,
  sendSignUpLinkLive,
  sendAdminSignUpLinkLive,
  sendIsoSignUpLinkLive,
  sendIsoAdminSignUpLinkLive,
  sendIrisGetFunction,
  sendAssignReward,
  // sendSMS,
  // tokenExchange,
  // updateToken,
  firebase as default
};