// /*eslint-disable*/
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Recaptcha from "react-google-recaptcha"
import { db } from 'firebase/client';
import loader from "assets/img/loader.gif";
import { sendTempPassword } from 'firebase/client';

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/merchantSignUpStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import tuzoLogo from "assets/img/tuzo/tuzoHorizontal.png"

// core components
import { Button, Card, Hidden, IconButton, TextField, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Close } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import PasswordPage from './PasswordPage';



export function RegisterPage(props) {
    const { classes, history } = props;
    const docId = window.location.pathname.split("/")[3]
    const [tempUser, setTempUser] = useState(false)
    const [checkCap, setCheckCap] = useState(false)
    const [companyName, setCompanyName] = useState("")
    const [isoLogo, setIsoLogo] = useState(tuzoLogo)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("");
    const [merchantId, setMerchantId] = useState("")
    const [checkForNext, setCheckForNext] = useState(false)



    useEffect(() => {
        if (checkCap && merchantId) {
            setCheckForNext(true)
        }
    }, [checkCap, merchantId])


    const generateTempPw = () => {
        let charList = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!^+%&/()=?_#${[]}|;:>÷`<.*-@'
        const charLength = charList.length
        let pw = ''

        for (let i = 0; i < 8; i++) {
            pw += charList.charAt(Math.floor(Math.random() * charLength)
            )
        }
        return pw
    }

    const sendTempPw = async () => {
        const generatePw = generateTempPw();
        // setNewTempPw(generatePw)
        const data = {
            ...tempUser,
            merchantId,
            newPw: generatePw
        }
        setTempUser(data)
        await db
            .collection('iso-temp-user')
            .doc(docId)
            .update({
                tempPw: generatePw
            })
            .then(() => {
                // sendTempPassword(data)
                // setNextPage(true)
                history.push(`/auth/iso-merchant-register/${docId}/${generatePw}`, {
                    tempUser: data,
                    isoLogo: isoLogo
                })
            })
    }

    useEffect(() => {
        if (!tempUser) {
            const getInfo = async () => {
                return db
                    .collection('iso-temp-user')
                    .doc(docId)
                    .get()
                    .then((snapshot) => {
                        const data = snapshot.data()
                        setTempUser(data)
                        setFirstName(data.firstName)
                        setLastName(data.lastName)
                        setCompanyName(data.companyName)
                        if (data.logo) {
                            setIsoLogo(data.logo)
                        }
                        setEmail(data.email)
                        setMerchantId(data.merchantId)
                    })
            }
            getInfo();
        }
        else { }

    }, [])

    if (!tempUser) {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        )
    }

    return (
        <div style={{ overflow: "auto" }}>
            <Hidden mdUp implementation='css'>
                <GridContainer>
                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <img className={classes.logoImg} src={isoLogo} />
                    </GridItem>

                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <Card className={classes.mobileIsoMainContainer}>
                            <CardHeader className={classes.cardHeaderTitle}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Sign Up
                                    </GridItem>

                                    <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                                        <IconButton
                                            style={{ color: "#004253", marginTop: "-15px" }}
                                            onClick={() => history.push(`/auth/iso-merchant-register/${docId}`)}
                                        >
                                            <Close />
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={12}
                                        style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
                                    >
                                        Step 1 of 2
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <Typography className={classes.textFieldLabel}>
                                            Company Name*
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={companyName}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            First Name
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={firstName}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Last Name
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setLastName(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={lastName}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Email*
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setEmail(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={email}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Merchant ID*
                                        </Typography>
                                        <TextField
                                            disabled
                                            className={classes.textFieldMobile}
                                            value={merchantId}
                                            // onChange={(e) => setMerchantId(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <div className={classes.captchaDiv}>
                                            <Recaptcha
                                                sitekey="6LcC51YoAAAAAIYCdO7dQ_BANZBPVLmKE_k3xAhU"
                                                onChange={() => setCheckCap(true)}
                                                onExpired={() => setCheckCap(false)}
                                                size="normal"
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12} >
                                        <Button
                                            className={classes.addIdButtonMobile}
                                            style={{ background: "#56CA93", width: "100%" }}
                                            disabled={!checkForNext}
                                            onClick={() => {
                                                sendTempPw()
                                                // callMerchantInfo()
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div >
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                                >
                                    Terms of Use
                                </span>
                            </div>

                            <div>
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                >
                                    Privacy Policy
                                </span>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </Hidden >


            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <img className={classes.logoImg} src={isoLogo} />
                    </GridItem>

                    <GridItem xs={12} className={classes.gridItemFlexCenter} style={{ marginTop: "10px" }}>
                        <Card className={classes.isoMainContainer}>
                            <CardHeader className={classes.cardHeaderTitle}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Sign Up
                                    </GridItem>

                                    <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                                        <IconButton
                                            style={{ color: "#004253", marginTop: "-15px" }}
                                            onClick={() => history.push(`/auth/iso-merchant-register/${docId}`)}
                                        >
                                            <Close />
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={12}
                                        style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
                                    >
                                        Step 1 of 2
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <div style={{ display: "flex", flexDirection: "column", height: "23vh", justifyContent: "space-between" }}>
                                            <Typography className={classes.textFieldLabel}>
                                                Company Name*
                                            </Typography>

                                            <Typography className={classes.textFieldLabel}>
                                                First Name
                                            </Typography>

                                            <Typography className={classes.textFieldLabel}>
                                                Last Name
                                            </Typography>

                                            <Typography className={classes.textFieldLabel}>
                                                Email*
                                            </Typography>

                                            <Typography className={classes.textFieldLabel} style={{ marginBottom: "-5px" }}>
                                                Merchant ID*
                                            </Typography>
                                        </div>
                                        {/* <Button
                                    className={classes.addIdButton}
                                    style={{ margin: "36px 0px 10px 0px" }}
                                >
                                    Add Merchant ID
                                </Button> */}
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div style={{ display: "flex", flexDirection: "column", height: "25vh", justifyContent: "space-between" }}>

                                            <TextField
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                className={classes.textField}
                                                value={companyName}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />

                                            <TextField
                                                onChange={(e) => setFirstName(e.target.value)}
                                                className={classes.textField}
                                                value={firstName}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />

                                            <TextField
                                                onChange={(e) => setLastName(e.target.value)}
                                                className={classes.textField}
                                                value={lastName}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />

                                            <TextField
                                                onChange={(e) => setEmail(e.target.value)}
                                                className={classes.textField}
                                                value={email}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />

                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                value={merchantId}
                                                onChange={(e) => setMerchantId(e.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <div style={{ transform: "scale(0.95)", marginTop: "5px", marginLeft: "-10px" }}>
                                            <Recaptcha
                                                sitekey="6LcC51YoAAAAAIYCdO7dQ_BANZBPVLmKE_k3xAhU"
                                                onChange={() => setCheckCap(true)}
                                                onExpired={() => setCheckCap(false)}
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            className={classes.addIdButton}
                                            style={{ background: "#56CA93", marginLeft: "15px" }}
                                            disabled={!checkForNext}
                                            onClick={() => {
                                                sendTempPw()
                                                // callMerchantInfo()
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div >
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                                >
                                    Terms of Use
                                </span>
                            </div>

                            <div>
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                >
                                    Privacy Policy
                                </span>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </Hidden>
        </div >
    )

}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RegisterPage));