const adStyle = (theme) => ({
  saveButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
    width: "35%",
    height: "42px",
    marginRight: "10px"
  },
  adListTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#1A405F",
  },
  adAddButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    background: "#E2A400",
    "&:hover": {
      background: "#E2A400",
    },
    marginLeft: "50px",
    borderRadius: "7px",
    paddingLeft: "15px",
    paddingRight:"15px"
  },
  adFormTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "22px",
    textTransform: "capitalize",
    color: "#1A405F",
    marginTop: "5px"
  },
  icon: {
    marginRight: "10px !important",
    marginTop: "-5px !important",
    width: "24px !important",
    height: "24px !important"
  },
  selectText: {
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F"
  },
  selectStatus: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    color: "#E2A400",
    borderRadius: "7px",
    lineHeight: "16px",
    width: "100%",
    height: "45px",
    textAlign: "center",
    fontWeight: "500",
  },
  selectMenuItem: {
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F"
  },
  textField: {
    width: "90%",
    background: "white",
    height: "35px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "1px solid #1A405F",
    paddingLeft: "10px",
    borderRadius: "7px"
  },
  card: {
    marginTop: "15px",
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    padding: "15px",
    borderRadius: "7px",
    boxShadow: "0px 2px 2px 0px #DADADA",
    align: "center",
    color: "#1A405F"
  },
  cardBackground: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    padding: "15px",
    borderRadius: "7px",
    boxShadow: "0px 2px 2px 0px #DADADA",
    color: "#1A405F",
    minHeight: "371px"
  },
  input: {
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    '&::placeholder': {
      opacity: 1,
      fontFamily: "Josefin Sans",
      fontWeight: 400,
      color: "#1A405F",
    },
  },
  cardHeader: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "19px",
    textTransform: "capitalize",
    color: "#1A405F"
  },
  imgSquare: {
    height: "170px",
    border: "2px dashed #56CA93",
    marginLeft: "-20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  imgLabel: {
    textAlign: "center",
    fontFamily: "Josefin Sans",
    fontSize: "15px",
    color: "#1A405F"
  },
  imgDescription: {
    fontFamily: "Josefin Sans",
    fontSize: "15px",
    color: "#1A405F",
    fontWeight: 400,
  },
  errText: {
    fontFamily: "Josefin Sans",
    fontSize: "15px",
    color: "red",
    fontWeight: 700,
    marginTop: "10px"
  },
  checkboxes: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
  },
  radioText: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#1A405F"
  },
  bannerTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "24px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    lineHeight: "24px",
  },
  transferButton: {
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "17px",
    color: "white",
    background: "#E2A400",
    textTransform: "capitalize",
    "&:hover, &:focus": {
      backgroundColor: "#E2A400",
    }
  },
  picDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "19px",
    color: "#FFFFFF",
    lineHeight: "24px",
    marginTop: "15px",
    textAlign: 'left',
    padding: "0px 20px"
  },
  tableRow: {
    height: "39px",
    background: "#FFFFFF !important"
  },
  tableRowOdd: {
    height: "39px",
    background: "#E8EBEF  !important"
  },
  tableHeaders: {
    border: "0.5px solid #1A405F",
    background: "#1A405F",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontSize: "17px",
    padding: "10px",
    textTransform: "uppercase",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #1A405F",
    padding: "10px 20px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1A405F",

  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    color: "#1A405F"
  },
  adsTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    padding: "0px 30px 10px 20px"
  },
  actionButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
    marginRight: "10px"
  },
  selectAsc: {
    color: "#1A405F",
    fontFamily: "Josefin Sans",
    background: "#FFFFFF",
    borderRadius: "7px",
    border: "2px solid #1A405F",
    width: "30%",
    height: "45px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  selectMenuItem: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    fontWeight: "500",
    fontSize: "16px"
  },
  selectMenuSort: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    background: "#FFFFFF",
    border: "2px solid #1A405F",
    borderRadius: "7px",
    width: "65%",
    height: "45px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 0px",
  },
  searchBar: {
    width: "100%",
    background: "white",
    height: "45px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "2px solid #1A405F",
    paddingLeft: "10px",
    borderRadius: "7px",
    color: "#1A405F"
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px"
  },
  searchButton: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "7px",
    background: "#1A405F",
    // display: "block",
    margin: "0px -1px !important",
    padding: "8px 14px !important",
    height: "45px",
    width: "120px",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#1A405F",
    },
  },
  adTypeTitle :{
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight:"16px"
  }

})

export default adStyle