import React, { useEffect } from 'react';
import loader from "assets/img/loader.gif";

// core components
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';




import Style from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';

export function ActionsPage(props) {
    const { classes, history, userType, userCompany, IsoAdminCompanyName } = props;
    useEffect(() => {
        if (userType && (userType === "Admin" || userType === "Administrator")) {
            history.push('/admin/dashboard')
        }
        else if (userType && userType === "IsoAdmin") {
            if (userCompany) {
                let company = userCompany.replace(/ /g, '').toLowerCase()
                history.push(`/iso-admin/${company}/merchants`)
            }
        }
        else if (userType && userType === "Staff") {
            if (userCompany) {
                let company = userCompany.replace(/ /g, '').toLowerCase()
                history.push(`/iso-staff/${company}/merchants`)
            }
        }
        else if (userType && userType === "IsoMerchants") {
            if (IsoAdminCompanyName && userCompany) {
                let company = IsoAdminCompanyName.replace(/ /g, '').toLowerCase()
                let merchant = userCompany.replace(/ /g, '').toLowerCase()
                history.push(`/iso-merchant/${company}/${merchant}/activity`)
            }
        }
        else if (userType && userType === "Merchants") {
            history.push('/merchant/activity')
        }
        else {
            window.location.href = window.location.href;
        }
    }, [])


    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img style={{ width: "20%" }} src={loader} alt="..." />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userCompany: state.auth.user.companyName,
        userType: state.auth.user.userType,
        IsoAdminCompanyName: state.auth.user.IsoAdminCompanyName,
    };
}

export default withRouter(compose(
    connect(mapStateToProps),
    withStyles(Style)
)(ActionsPage));