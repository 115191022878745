/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import AuthLayout from 'layouts/Auth.js';
import MerchantsLayout from 'layouts/Merchants.js';
import AdminLayout from 'layouts/Admin.js';
import IsoAdminLayout from 'layouts/IsoAdminLayout';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from 'store/reducers/root';

import RegistrationActions from 'store/actions/forms/registration';
import AuthenticationActions from 'store/actions/auth';
import SystemActions from 'store/actions/system';

import { chain } from 'store/actions/util';
import { push } from 'connected-react-router';

import { firebase } from 'firebase/client';
import '@firebase/auth';


import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';
import RegisterPage from 'views/Pages/merchantSignUp/RegisterPage';
import IsoMerchantRegistPage from 'views/Pages/isoMerchantSignUp/RegisterPage'
import AdminRegisterPage from 'views/Pages/adminsignup/RegisterPage'
import { getPdf } from 'store/actions/termsAndConditionAction';
import { getRewards } from 'store/actions/rewardsList';
import { getWishlist } from 'store/actions/wishlistAction';
import { getNotifications } from 'store/actions/notificationsActions';
import IsoMerchantLayout from 'layouts/IsoMerchantLayout';
import IsoCalculator from 'views/Pages/isopubliccalculator/Calculator';
import IsoAdminSignUp from 'views/Pages/isoSignUp/RegisterPage'
import IsoLogin from "views/Pages/isoLogin/LoginPage"
import Calculator from 'views/Pages/calculator/Calculator';
import Referral from 'views/Pages/referral/Referral';
import IsoStaffLayout from 'layouts/IsoStaffLayout';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const hist = createBrowserHistory();

const stateStore = createStore(
  rootReducer(hist),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(hist), logger))
);

// Initialize anything that doesn't require a login
stateStore.dispatch(RegistrationActions.initialize());

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // Do initialization that requires user login
    stateStore.dispatch(
      chain(
        AuthenticationActions.startLoadProfile(user, hist),
        // LeadsActions.initialize(),
        // CustomersActions.initialize(),
        // UsersActions.initialize(),
        SystemActions.setReady(true),
        getRewards(),
        getWishlist(user.uid),
        getNotifications(user.uid)
        // getDemographics(),
        // getLeads(user.uid),
        // getLists(user.uid),
        // getCustomers(user.uid)
      )
    );
  } else {
    stateStore.dispatch(
      getPdf()
    );
    if (window.location.pathname.includes('/merchant-register')) {
      return (
        <Route path="auth/merchant-register/:id" component={RegisterPage} />
      )
    }
    if (window.location.pathname.includes('/admin-register')) {
      return (
        <Route path="auth/admin-register/:id" component={AdminRegisterPage} />
      )
    }
    if (window.location.pathname.includes('/iso-register')) {
      return (
        <Route path="auth/merchant-register/:id" component={IsoAdminSignUp} />
      )
    }
    if (window.location.pathname.includes('/iso-merchant-register')) {
      return (
        <Route path="auth/iso-merchant-register/:id" component={IsoMerchantRegistPage} />
      )
    }
    if (window.location.pathname.includes('/iso-calculator')) {
      return (
        <Route path="auth/:merchant/iso-calculator" component={IsoCalculator} />
      )
    }
    if (window.location.pathname.includes('/calculator')) {
      return (
        <Route path="auth/calculator" component={Calculator} />
      )
    }
    if (window.location.pathname.includes('/referral')) {
      return (
        <Route path="auth/referral/indipop" component={Referral} />
      )
    }
    if (window.location.pathname.split('/').includes("login") && window.location.pathname.split('/').length === 4) {
      return (
        <Route path="auth/:iso/iso-login-page" component={IsoLogin} />
      )
    }
    else {
      stateStore.dispatch(push('/auth/login-page'));
    }
  }
});

ReactDOM.render(
  <Provider store={stateStore}>
    <ConnectedRouter history={hist}>
      <Switch>
        <Route path='/iso-merchant' component={IsoMerchantLayout} />
        <Route path='/iso-admin' component={IsoAdminLayout} />
        <Route path='/iso-staff' component={IsoStaffLayout} />
        <Route path='/merchant' component={MerchantsLayout} />
        <Route path='/auth' component={AuthLayout} />
        <Route path='/admin' component={AdminLayout} />
        <Redirect from='/' to='/admin/actions' />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
