import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { db, firebase } from 'firebase/client.js';
import moment from "moment"
// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/loader.gif";

// core components
import { Button, Hidden, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';

// icons
import { Add } from '@material-ui/icons';
import AdsTable from './adsListComponents/AdsTable';
import Card from 'components/Card/Card';
import { Filter } from './adsListComponents/Filter';

const headers = [
    "Title",
    "Status",
    "Location",
    "Created",
    "Actions"
];

const sortArr = [
    "Title",
    "Status",
    "Location",
    "Created",
]

const ascArr = [
    "Asc",
    "Desc"
]

export function Ads(props) {
    const {
        classes,
        history
    } = props;

    const [adsData, setAdsData] = useState(false)
    const [copyArr, setCopyArr] = useState(false)
    const itemsPerPage = 5;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [sortDropdown, setSortDropdown] = useState("Title")
    const [ascDropdown, setAscDropdown] = useState("Asc")
    const [searchString, setSearchString] = useState("")



    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const resetPageFilter = (filterArr) => {
        setAdsData([...filterArr])
        setIndex(0)
        setEndIndex(itemsPerPage)
        setPageNumber(0)
    }

    const handleFilter = (ascValue, sortValue) => {
        if (ascValue === "Asc") {
            if (sortValue === "Title") {
                const filtered = copyArr.sort(function (a, b) {
                    if (a.adName < b.adName) { return -1; }
                    if (a.adName > b.adName) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Status") {
                const filtered = copyArr.sort(function (a, b) {
                    if (a.status < b.status) { return -1; }
                    if (a.status > b.status) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Created") {
                const filtered = copyArr.sort(function (a, b) {
                    return a.timestamp.seconds - b.timestamp.seconds;
                })
                resetPageFilter(filtered)
            }
            // if (sortValue === "Category") {
            //     let filtered = []
            //     let copy = [...copyArr]
            //     let newObj = {}
            //     Object.keys(copy).map((key) => {
            //         let val = copy[key]["categories"];
            //         Object.keys(val).map((valKey) => {
            //             console.log("checking valKey", val)
            //             if (val[valKey] === true) {
            //                 copy[key]["filteredCategory"] = valKey
            //                 filtered.push(copy[key])
            //             }
            //         })
            //     });
            //     filtered.sort(function (a, b) {
            //         if (a.filteredCategory < b.filteredCategory) { return -1; }
            //         if (a.filteredCategory > b.filteredCategory) { return 1; }
            //         return 0;
            //     })
            //     resetPageFilter(filtered)
            // }
        }
        if (ascValue === "Desc") {
            if (sortValue === "Title") {
                const filtered = copyArr.sort(function (a, b) {
                    if (a.adName > b.adName) { return -1; }
                    if (a.adName < b.adName) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Status") {
                const filtered = copyArr.sort(function (a, b) {
                    if (a.status < b.status) { return -1; }
                    if (a.status > b.status) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Created") {
                const filtered = copyArr.sort(function (a, b) {
                    return b.timestamp.seconds - a.timestamp.seconds;
                })
                resetPageFilter(filtered)
            }
            // if (sortValue === "Category") {
            //     let filtered = []
            //     let copy = [...copyArr]
            //     Object.keys(copy).map((key) => {
            //         let val = copy[key]["categories"];
            //         Object.keys(val).map((valKey) => {
            //             console.log("checking valKey", val)
            //             if (val[valKey] === true) {
            //                 copy[key]["filteredCategory"] = valKey
            //                 filtered.push(copy[key])
            //             }
            //         })
            //     });
            //     filtered.sort(function (a, b) {
            //         if (a.filteredCategory > b.filteredCategory) { return -1; }
            //         if (a.filteredCategory < b.filteredCategory) { return 1; }
            //         return 0;
            //     })
            //     resetPageFilter(filtered)
            // }
        }
    }

    const handleSearch = () => {
        if (searchString) {
            const filtered = copyArr.filter((f) => {
                if (
                    f.adName.toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true
                }
            })
            setAdsData(filtered)
        }
        else {
            setAdsData(copyArr)
        }
    }
    useEffect(() => {
        if (!adsData) {
            const getAdData = () => {
                return db.collection('ad-list').get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            const arr = []
                            snapshots.forEach((doc) => {
                                arr.push(doc.data())
                            })
                            setAdsData(arr)
                            setCopyArr(arr)
                        }
                        else {
                            setAdsData([])
                        }
                    })
            }
            getAdData()
        }
    }, [])

    if (!adsData)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        )

    return (
        <div>
            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.adListTitle}>
                            Promotional Ads
                        </Typography>

                        <Button className={classes.adAddButton} onClick={() => history.push('/admin/ads/create-ad')}>
                            <Add style={{ marginRight: "5px" }} /> Create
                        </Button>
                    </GridItem>
                    <Card className={classes.card}>
                        <GridItem xs={12}>
                            <Filter
                                classes={classes}
                                sortArr={sortArr}
                                ascArr={ascArr}
                                searchString={searchString}
                                setSearchString={setSearchString}
                                ascDropdown={ascDropdown}
                                setAscDropdown={setAscDropdown}
                                sortDropdown={sortDropdown}
                                setSortDropdown={setSortDropdown}
                                handleFilter={handleFilter}
                                handleSearch={handleSearch}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <AdsTable
                                classes={classes}
                                headers={headers}
                                adsData={adsData}
                                pageNumber={pageNumber}
                                index={index}
                                endIndex={endIndex}
                                itemsPerPage={itemsPerPage}
                                handlePageClick={handlePageClick}
                            />
                        </GridItem>
                    </Card>
                </GridContainer>

            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        userId: state.auth.user.uid
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Ads);