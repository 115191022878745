import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
import { db } from 'firebase/client';
import Table from './components/Table';
import MobileTable from './components/MobileTable';
import { CSVLink } from "react-csv";
// mui
import { Button, Card, Hidden, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/loader.gif";
import tuzoBg from "assets/img/tuzo/tallBg.png";
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';
import { MdKeyboardArrowLeft } from 'react-icons/md';

// icons
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";


const headers = [
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Reference", key: "referenceNum" },
    { label: "Date", key: "date" },
    { label: "Amount", key: "Amount" },
    { label: "Points", key: "pts" },
];

// const csvHeaders = [
//     { label: "merchantName", key: "name" },
//     { label: "merchantNumber", key: "merchantNumber" },
//     { label: "email", key: "email" },
//     { label: "reportDate", key: "reportDate" },
//     { label: "transactionCount", key: "transactionCount" },
//     { label: "avgTrans", key: "avgTrans" },
//     { label: "batchNumber", key: "batchNumber" },
//     { label: "netAmount", key: "netAmount" },
//     { label: "prepaidDiscount", key: "prepaidDiscount" },
//     { label: "returnAmount", key: "returnAmount" },
//     { label: "saleAmount", key: "saleAmount" },
//     { label: "terminalNumber", key: "terminalNumber" },
// ];

export function BatchDetails(props) {
    const { classes, history, user } = props;
    const [detailsData, setDetailsData] = useState(false)
    const [batchData, setBatchData] = useState(false)
    const [merchantName, setMerchantName] = useState("")
    const itemsPerPage = 9;
    const itemsPerPageMobile = 3;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile)
    const [points, setPoints] = useState([])
    const [docId, setDocId] = useState("")
    const [subId, setSubId] = useState("")

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });


    useEffect(() => {
        if (user.userType === "IsoMerchants") {
            setDocId(window.location.pathname.split("/")[6])
            setSubId(window.location.pathname.split("/")[7])
        } else if (user.userType === "Admin" || user.userType === "Merchants") {
            setDocId(window.location.pathname.split("/")[4])
            setSubId(window.location.pathname.split("/")[5])
        }
        else {
            setDocId(window.location.pathname.split("/")[5])
            setSubId(window.location.pathname.split("/")[6])
        }
    }, [])

    useEffect(() => {
        if (detailsData) {
            setPoints([])
            detailsData.map((d) => {
                const floorDollarPerBassisPts = ptsFormula(Number(d.transactionAmount), user.userBasisPts)
                setPoints(prevArr => [...prevArr, floorDollarPerBassisPts])
            })
        }
    }, [detailsData])

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    useEffect(() => {
        if (!batchData && docId) {
            const getData = () => {
                const batchDb = db.collection('merchants-live').doc(docId).collection('batch-summary').doc(subId)
                const merchDb = db.collection('merchants-live').doc(docId)
                return merchDb
                    .get()
                    .then((snapshot) => {
                        setMerchantName(snapshot.data().merchantName)
                    })
                    .then(() => {
                        batchDb
                            .get()
                            .then((snapshot) => {
                                setBatchData(snapshot.data())
                            })
                            .then(() => {
                                batchDb.collection('batch-details')
                                    .orderBy('transactionTime', 'asc')
                                    .onSnapshot((snapshot) => {
                                        const arr = [];
                                        snapshot.forEach((doc) => {
                                            arr.push(doc.data())
                                        })
                                        setDetailsData(arr)
                                    })
                            })
                    })
            }
            getData()
        }
    })

    const getBgImage = () => {
        return tuzoBg;
    }

    if (!batchData || !detailsData || !docId || !subId)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );


    return (
        <div>
            <Hidden mdUp implementation='css'>
                <GridContainer style={{ marginTop: "50px" }}>
                    <GridItem xs={12}>
                        <Typography variant="h2" className={classes.titleMobile}>
                            Batch #{batchData.batchNumber} Details
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <Button
                            onClick={() => {
                                if (user.IsoAdminCompanyName) {
                                    history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reports/batch`)
                                } else {
                                    history.push('/merchant/reports/batch')
                                }
                            }}
                            className={classes.backButton}>
                            <MdKeyboardArrowLeft
                                style={{ fontSize: "32px", marginTop: "-2px" }} />
                            Back to Reports
                        </Button>
                    </GridItem>
                </GridContainer>

                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "100%",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        padding: "20px"
                    }}
                >

                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Total
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Transactions
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfoBoldTopMobile}>
                                            ${Number(batchData.netAmount).toFixed(2)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoBoldMobile}>
                                            {batchData.transactionCount}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} className={classes.marginTopGrid}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Date
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Time Posted
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("MMM DD, YYYY")}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("LT")}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} className={classes.marginTopGrid}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Points Earned
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoMobileLineheight}>
                                            Transaction Location
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {ptsFormula(batchData.netAmount, user.userBasisPts)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoMobileLineheight}>
                                            {merchantName}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    <GridItem xs={12} style={{ marginTop: "15px" }}>
                        <Typography
                            variant="h2"
                            className={classes.title}
                            style={{ fontSize: "19px", lineHeight: "19px", padding: "20px 0px" }}
                        >
                            {batchData.transactionCount} Transactions
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <MobileTable
                            detailsData={detailsData}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={mobileEndIndex}
                            setEndIndex={setMobileEndIndex}
                            itemsPerPage={itemsPerPageMobile}
                            headers={headers}
                            history={history}
                            points={points}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer >
                    <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h2" className={classes.title}>
                            Batch #{batchData.batchNumber} Details
                        </Typography>

                        <Button
                            onClick={() => {
                                if (user.IsoAdminCompanyName) {
                                    history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reports/batch`)
                                } else {
                                    history.push('/merchant/reports/batch')
                                }
                            }}
                            className={classes.backButton}>
                            <MdKeyboardArrowLeft
                                style={{ fontSize: "32px", marginTop: "-2px" }} />
                            Back to Reports
                        </Button>
                    </GridItem>
                </GridContainer>

                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "225px",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        padding: "20px"
                    }}
                >

                    <GridContainer>
                        <GridItem xs={4}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Total
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Transactions
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfoBoldTop}>
                                            ${Number(batchData.netAmount).toFixed(2)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoBold}>
                                            {batchData.transactionCount}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={4}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Date
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Time Posted
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("MMM DD, YYYY")}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("LT")}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={4}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Points Earned
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Transaction Location
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {ptsFormula(batchData.netAmount, user.userBasisPts)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {merchantName}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    <GridItem xs={6} style={{ marginTop: "15px", alignContent: "center" }}>
                        <Typography
                            variant="h2"
                            className={classes.title}
                            style={{ fontSize: "19px", lineHeight: "19px" }}
                        >
                            {batchData.transactionCount} Transactions
                        </Typography>
                    </GridItem>

                    {/* <GridItem xs={6} style={{ marginTop: "15px", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            className={classes.csvButton}
                            style={{ width: "30%" }}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={csvHeaders}
                                data={
                                    detailsData.map(d => ({
                                        merchantNumber: d.merchantNumber,
                                        // name: d[0].merchantName,
                                        // email: d[0].email,
                                        // netAmount: numberFormatterDecimals.format(d[0].netAmount),
                                        // prepaidDiscount: numberFormatterDecimals.format(d[0].prepaidDiscount),
                                        // reportDate: moment(d[0].reportDate).format("MM/DD/YY"),
                                        // returnAmount: numberFormatterDecimals.format(d[0].returnAmount),                                   
                                        // pointsEarned: numberFormatter.format(d[1]),
                                        // saleAmount: numberFormatterDecimals.format(d[0].saleAmount),  
                                        // terminalNumber: d[0].terminalNumber,
                                        // transactionCount: d[0].transactionCount,
                                        // merchantNumber: d[0].merchantNumber,
                                        // name: d[0].merchantName,
                                        // quantity: d[0].transactionCount,
                                        // pointsEarned: numberFormatter.format(d[1]),
                                        // dayTotal: numberFormatterDecimals.format(d[0].netAmount),
                                        // reportDate: moment(d[0].reportDate).format("MM/DD/YY"),
                                        // userBasisPts: d.userBasisPts,

                                    }))
                                }
                                filename={`batch_summary_details`}
                            >
                                <CSVIcon style={{ marginRight: "10px", marginBottom: "-5px" }} /> Export CSV

                            </CSVLink>
                        </Button>
                    </GridItem> */}

                    <GridItem xs={12}>
                        <Table
                            detailsData={detailsData}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={endIndex}
                            setEndIndex={setEndIndex}
                            itemsPerPage={itemsPerPage}
                            handlePageClick={handlePageClick}
                            headers={headers}
                            history={history}
                            points={points}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(BatchDetails));