const assignRewardStyle = (theme) => ({
    title: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "30px",
        textTransform: "capitalize",
        color: "#1A405F"
    },
    cardTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#1A405F"
    },
    cancel: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#56CA93",
        height: "42px",
        width: "180px",
        margin: "-5px 0px 0px 20px",
        "&:hover, &:focus": {
            backgroundColor: "#56CA93",
        },
        borderRadius: "7px",
    },
    icon: {
        marginRight: "10px",
        marginTop: "-5px"
    },
    searchBar: {
        width: "50%",
        background: "white",
        height: "40px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "2px solid #1A405F",
        paddingLeft: "10px",
        borderRadius: "7px",
        color: "#1A405F"
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "35px"
    },
    searchButton: {
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "7px",
        background: "#1A405F",
        // display: "block",
        margin: "0px -1px !important",
        padding: "8px 14px !important",
        height: "40px",
        width: "120px",
        color: "#FFFFFF",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        textTransform: "capitalize",
        "&:hover": {
            background: "#1A405F",
        },
    },
    option: {
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        color: "#1A405F"
    },
    optionUser: {
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        color: "#1A405F",
        whiteSpace: "pre-line",
        border: "1px solid #1A405F"
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    card: {
        padding: "15px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
    },
    detailCard: {
        padding: "15px",
        // border: "0.5px solid #56CA93",
        borderRadius: "7px",
        minHeight: "90px",
    },
    detailDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginRight: "10px",
        whiteSpace: "nowrap"
    },
    userDetailDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        whiteSpace: "nowrap"
    },
    userDetailDescMarginTop: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        whiteSpace: "nowrap",
        marginTop: "5px"
    },
    userdetailDescUnderline: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        whiteSpace: "nowrap",
        textDecoration: "underline",
        "&:hover": {
            cursor: "pointer"
        }
    },
    rewardDetailName: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "18px"
        },
    },
    rewardDetailDescription: {
        fontFamily: "Josefin Sans !important",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "21px",
        marginTop: "15px"
    },
    cardListImg: {
        width: "180px",
        height: "150px",
        objectFit: "contain"
    },
    totalCostPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "19px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "16px"
        },
        whiteSpace: "noWrap"
    },
    totalCost: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "18px"
        },
    },
    borderDiv: {
        paddingTop: "20px",
        paddingBottom: "10px",
        borderBottom: "1px solid #1A405F"
    },
    tripTotal: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#1A405F",
    },
    itemSummary: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#1A405F",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    remainingPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#1A405F",
    },
    sendEmailBtn: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        background: "#1A405F",
        color: "#FFFFFF",
        width: "100%",
        borderRadius: "7px",
        height: "42px",
        marginTop: "15px",
        "&:disabled": {
            color: "#FFFFFF"
        },
        "&:hover" : {
            background:"#06667C"
        },
        textTransform: "capitalize"
    },
    gridItemRadio: {
        display: "flex",
    },
    checkboxesLabel: {
        fontFamily: "Josefin Sans",
        color: "#56CA93",
    },
    radioButton: {
        marginRight: "-15px",
        marginTop: "-10px",
        '&, &.Mui-checked': {
            color: '#56CA93',
        },
    },
    ptsTextField: {
        width: "25%",
        background: "white",
        height: "30px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        border: "1px solid #1A405F",
        paddingLeft: "5px",
        borderRadius: "7px",
        marginTop: "-3px",
        marginLeft: "10px",
        [theme.breakpoints.down('1400')]: {
            width:"100%"
        }
    },
})

export default assignRewardStyle;