import React, { useEffect, useState } from 'react';
import DonutChart from 'react-donut-chart';
import loader from "assets/img/loader.gif";

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add, ArrowBackIos, ArrowLeft, Cancel, CheckCircle } from '@material-ui/icons';

import Style from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';


import Filter from './components/Filter';
import Table from './components/Table';
import { Checkbox, Dialog, FormControl, FormControlLabel, FormGroup, Link, TextField, TextareaAutosize, Typography } from '@material-ui/core';
import { db, firebase } from 'firebase/client';
import users from 'store/actions/explorers/users';
import { zip } from 'lodash';
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';
import { BiSolidLeftArrow } from 'react-icons/bi';

const listArr = [
    "Points",
    "Pending Points",
    "Name",
    "Company Name",
    "Email",
]

const analyticsArr = [
    "Points",
    "Name",
    "Joined"
]

const ascArr = [
    "Asc",
    "Desc"
]

const userTypeArr = [
    "Administrator",
    "ISO",
    "Staff",
    "Merchants",
]

const userHeaders = [
    { label: "Points", key: "pts" },
    { label: "Name", key: "name" },
    { label: "Company Name", key: "companyName" },
    { label: "Email", key: "email" },
    { label: "Type", key: "type" },
    { label: "Status", key: "status" },
    { label: "Joined", key: "joined" },
];

const listHeaders = [
    { label: "Points", key: "pts" },
    { label: "Pending Points", key: "pendingPts" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Merchant #", key: "merchantNumber" },
    { label: "Company Name", key: "companyName" },
    { label: "Date", key: "reportDate" },
    { label: "Basis Points", key: "userBasisPts" },
];

export function DashboardPage(props) {
    const { classes, history, userType } = props;
    const [statusArr, setStatusArr] = useState(analyticsArr)
    const [rewardsSwitch, setRewardsSwitch] = useState("All Users")
    const [statusDropdown, setStatusDropdown] = useState("Points")
    const [ascDropdown, setAscDropdown] = useState("Asc")
    const [searchString, setSearchString] = useState("")
    const [merchantLen, setMerchantLen] = useState("")
    const [transactionLen, setTransactionLen] = useState("")
    const [listSwitch, setListSwitch] = useState("Analytics")
    const itemsPerPage = 6;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [usersData, setUsersData] = useState(false)
    const [copyArr, setCopyArr] = useState(false)
    const [points, setPoints] = useState([])
    const [zippedArr, setZippedArr] = useState(false)
    const [copyZippedArr, setZippedCopyArr] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [ptsToApprove, setPtsToApprove] = useState("")
    const [message, setMessage] = useState("")
    const [merchantContact, setMerchantContact] = useState("")
    const [batchInfo, setBatchInfo] = useState("")
    const [email, setEmail] = useState("")
    const [confirmed, setConfirmed] = useState(false)
    const [denied, setDenied] = useState(false)
    const [userTypeModal, setUserTypeModal] = useState(false)
    const [userTypeArrFilter, setUserTypeArrFilter] = useState([])
    const [userSelectAll, setUserSelectAll] = useState(false)

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const handleRewardsToggle = (value) => {
        setRewardsSwitch(value)
    }

    const handleListToggle = () => {
        setPageNumber(0)
        setIndex(0)
        setEndIndex(itemsPerPage)
        setUsersData(false)
        setUserTypeArrFilter([])
        if (listSwitch === "List") {
            setListSwitch("Analytics")
            setAscDropdown("Asc")
            setStatusDropdown("Points")
            setStatusArr(analyticsArr)
            getUserData()
        }
        else {
            setListSwitch("List")
            setAscDropdown("Asc")
            setStatusDropdown("Points")
            setStatusArr(listArr)
            getData()
        }
    }

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const resetPageFilter = (filterArr) => {
        setZippedArr([...filterArr])
        setIndex(0)
        setEndIndex(itemsPerPage)
        setPageNumber(0)
    }

    const resetModal = () => {
        setMerchantContact("")
        setEmail("")
        setPtsToApprove("")
        setMessage("")
        setBatchInfo("")
        setApproveModal(false)
    }

    const handleFilter = (ascValue, sortValue) => {
        if (ascValue === "Asc") {
            if (sortValue === "Points") {
                const filtered = zippedArr.sort((a, b) =>
                    (a[0].approvedPts != null ? a[0].approvedPts : -Infinity) - (b[0].approvedPts != null ? b[0].approvedPts : -Infinity)
                )
                resetPageFilter(filtered)
            }
            if (sortValue === "Pending Points") {
                const filtered = zippedArr.sort((a, b) =>
                    (a[1] - b[1])
                )
                resetPageFilter(filtered)
            }
            if (sortValue === "Name") {
                const filtered = zippedArr.sort(function (a, b) {
                    if (a[0].contact) {
                        if (a[0].contact < b[0].contact) { return -1; }
                        if (a[0].contact > b[0].contact) { return 1; }
                        return 0;
                    } else {
                        if (a[0].lastName < b[0].lastName) { return -1; }
                        if (a[0].lastName > b[0].lastName) { return 1; }
                        return 0;
                    }

                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Company Name") {
                const filtered = zippedArr.sort(function (a, b) {
                    if (a[0].merchantName < b[0].merchantName) { return -1; }
                    if (a[0].merchantName > b[0].merchantName) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Email") {
                const filtered = zippedArr.sort(function (a, b) {
                    if (a[0].email < b[0].email) { return -1; }
                    if (a[0].email > b[0].email) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "User Type") {
                if (userTypeArrFilter.length > 0 && !userTypeArrFilter.includes("All")) {
                    let userArr = []
                    copyZippedArr.map((data) => {
                        if (
                            userTypeArrFilter.includes("Administrator") &&
                            (data[0].userType === "Administrator" || data[0].userType === "Admin")
                        ) {
                            userArr.push(data)
                        }
                        else if (
                            userTypeArrFilter.includes("ISO") && data[0].userType === "IsoAdmin"
                        ) {
                            userArr.push(data)
                        }
                        else if (
                            (data[0].userType.includes("Merchant") && userTypeArrFilter.includes("Merchants"))
                        ) {
                            userArr.push(data)
                        }
                        else {
                            if (userTypeArrFilter.includes(data[0].userType)) {
                                userArr.push(data)
                            }
                        }
                    })
                    userArr.sort(function (a, b) {
                        if (a[0].userType < b[0].userType) { return -1; }
                        if (a[0].userType > b[0].userType) { return 1; }
                        return 0;
                    })
                    resetPageFilter(userArr)
                }
                else {
                    const filtered = zippedArr.sort(function (a, b) {
                        if (a[0].userType < b[0].userType) { return -1; }
                        if (a[0].userType > b[0].userType) { return 1; }
                        return 0;
                    })
                    resetPageFilter(filtered)
                }
            }
            if (sortValue === "Joined") {
                const filtered = zippedArr.sort(function (a, b) {
                    return (a[0].timestamp != null ? a[0].timestamp.seconds : -Infinity) -
                        (b[0].timestamp != null ? b[0].timestamp.seconds : -Infinity);
                })
                resetPageFilter(filtered)
            }
        }
        if (ascValue === "Desc") {
            if (sortValue === "Points") {
                const filtered = zippedArr.sort((a, b) =>
                    (b[0].approvedPts != null ? b[0].approvedPts : -Infinity) - (a[0].approvedPts != null ? a[0].approvedPts : -Infinity)
                )
                resetPageFilter(filtered)
            }
            if (sortValue === "Pending Points") {
                const filtered = zippedArr.sort((a, b) =>
                    (b[1] - a[1])
                )
                resetPageFilter(filtered)
            }
            if (sortValue === "Name") {
                const filtered = zippedArr.sort(function (a, b) {
                    if (a[0].contact > b[0].contact) { return -1; }
                    if (a[0].contact < b[0].contact) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Company Name") {
                const filtered = zippedArr.sort(function (a, b) {
                    if (a[0].merchantName > b[0].merchantName) { return -1; }
                    if (a[0].merchantName < b[0].merchantName) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Email") {
                const filtered = zippedArr.sort(function (a, b) {
                    if (a[0].email > b[0].email) { return -1; }
                    if (a[0].email < b[0].email) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "User Type") {
                if (userTypeArrFilter.length > 0 && !userTypeArrFilter.includes("All")) {
                    let userArr = []
                    copyZippedArr.map((data) => {
                        if (
                            userTypeArrFilter.includes("Administrator") &&
                            (data[0].userType === "Administrator" || data[0].userType === "Admin")
                        ) {
                            userArr.push(data)
                        }
                        else if (
                            userTypeArrFilter.includes("ISO") && data[0].userType === "IsoAdmin"
                        ) {
                            userArr.push(data)
                        }
                        else if (
                            (data[0].userType.includes("Merchant") && userTypeArrFilter.includes("Merchants"))
                        ) {
                            userArr.push(data)
                        }
                        else {
                            if (userTypeArrFilter.includes(data[0].userType)) {
                                userArr.push(data)
                            }
                        }
                    })
                    userArr.sort(function (a, b) {
                        if (a[0].userType > b[0].userType) { return -1; }
                        if (a[0].userType < b[0].userType) { return 1; }
                        return 0;
                    })
                    resetPageFilter(userArr)
                } else {
                    const filtered = zippedArr.sort(function (a, b) {
                        if (a[0].userType > b[0].userType) { return -1; }
                        if (a[0].userType < b[0].userType) { return 1; }
                        return 0;
                    })
                    resetPageFilter(filtered)
                }
            }
            if (sortValue === "Joined") {
                const filtered = zippedArr.sort(function (a, b) {
                    return (b[0].timestamp != null ? b[0].timestamp.seconds : -Infinity) -
                        (a[0].timestamp != null ? a[0].timestamp.seconds : -Infinity);
                })
                resetPageFilter(filtered)
            }
        }
    }

    const handleApprove = async () => {
        let str = "Your pending points have been confirmed and successfully added to your overall Tuzo points! It's time to check – you may have enough now for items on your Wish List."
        await db.collection('users')
            .where('merchantId', '==', batchInfo.merchantNumber)
            .get()
            .then(function (snapshots) {
                snapshots.forEach(function (s) {
                    let sId = s.id
                    s = s.data()
                    let newApprovePts = 0
                    let newPendingPts = 0
                    newPendingPts = s.tuzoPoints - Number(ptsToApprove)
                    if (s.approvedPts) {
                        newApprovePts = s.approvedPts + Number(ptsToApprove)
                    } else {
                        newApprovePts = Number(ptsToApprove)
                    }
                    db.collection('users').doc(sId).update({
                        tuzoPoints: newPendingPts,
                        approvedPts: newApprovePts
                    })
                    const notiId = db.collection('users').doc(sId).collection('notifications').doc().id
                    db.collection('users').doc(sId).collection('notifications').add({
                        data: str,
                        message,
                        read: false,
                        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                        type: "Points Approved",
                        docId: notiId
                    })
                        .then(() => {
                            db.collection('merchants-live')
                                .doc(batchInfo.parentDocId)
                                .collection('batch-summary')
                                .doc(batchInfo.docId)
                                .update({
                                    approved: true
                                })
                        }).then(() => {
                            setConfirmed(true)
                            setApproveModal(false)
                        })
                })
            })
    }

    const handleDenied = async () => {
        let str = "We regret to inform you that your pending points have not been approved. Contact us for further details or guidance on how to resolve this issue."
        await db.collection('users')
            .where('merchantId', '==', batchInfo.merchantNumber)
            .get()
            .then(function (snapshots) {
                snapshots.forEach(function (s) {
                    let sId = s.id
                    s = s.data()
                    const notiId = db.collection('users').doc(sId).collection('notifications').doc().id
                    db.collection('users').doc(sId).collection('notifications').add({
                        data: str,
                        message,
                        read: false,
                        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                        type: "Points Denied",
                        docId: notiId
                    })
                        .then(() => {
                            db.collection('merchants-live')
                                .doc(batchInfo.parentDocId)
                                .collection('batch-summary')
                                .doc(batchInfo.docId)
                                .update({
                                    denied: true
                                })
                        }).then(() => {
                            setDenied(true)
                            setApproveModal(false)
                        })
                })
            })
    }

    const handleSearch = () => {
        if (searchString) {
            const filtered = copyArr.filter((f) => {
                if (listSwitch === "Analytics") {
                    if (
                        f.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
                        f.lastName.toLowerCase().includes(searchString.toLowerCase())
                    ) {
                        return true
                    }
                }
                else {
                    if (
                        f.contact && f.contact.toLowerCase().includes(searchString.toLowerCase()) ||
                        f.merchantName && f.merchantName.toLowerCase().includes(searchString.toLowerCase()) ||
                        f.email && f.email.toLowerCase().includes(searchString.toLowerCase())
                    ) {
                        return true
                    }
                }

            })
            setUsersData(filtered)
        }
        else {
            setUsersData(copyArr)
        }
    }

    const handleSelectAll = () => {
        const value = !userSelectAll
        if (value) {
            setUserTypeArrFilter(userTypeArr)
        } else {
            setUserTypeArrFilter([])
        }

    }

    const handleUserTypeFilter = (event) => {
        let userArr = []
        const {
            target: { value },
        } = event;
        setUserTypeArrFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        if (value.length === 0) {
            setZippedArr(copyZippedArr)
        } else {
            copyZippedArr.map((data) => {
                if (
                    value.includes("Administrator") &&
                    (data[0].userType === "Administrator" || data[0].userType === "Admin")
                ) {
                    userArr.push(data)
                }
                else if (
                    value.includes("ISO") && data[0].userType === "IsoAdmin"
                ) {
                    userArr.push(data)
                }
                else if (
                    (data[0].userType.includes("Merchant") && value.includes("Merchants"))
                ) {
                    userArr.push(data)
                }
                else {
                    if (value.includes(data[0].userType)) {
                        userArr.push(data)
                    }
                }
            })
            if (ascDropdown === "Asc") {
                userArr.sort(function (a, b) {
                    if (a[0].userType < b[0].userType) { return -1; }
                    if (a[0].userType > b[0].userType) { return 1; }
                    return 0;
                })
                resetPageFilter(userArr)
            }
            if (ascDropdown === "Desc") {
                userArr.sort(function (a, b) {
                    if (a[0].userType > b[0].userType) { return -1; }
                    if (a[0].userType < b[0].userType) { return 1; }
                    return 0;
                })
                resetPageFilter(userArr)
            }
        }

    }

    const getData = async () => {
        const merchDb = db.collection('merchants-live')
        let docArr = []
        const arr = []
        let count = 0;
        let transCount = 0;

        await merchDb.get()
            .then(async (querySnapshot) => {
                querySnapshot.docs.forEach(async (doc) => {
                    let docId = doc.data().docId
                    let mid = doc.data().merchantNumber
                    let contact = doc.data().contact
                    let email = doc.data().email
                    let approvedPts
                    let userBasisPts = 0
                    let userType = ""
                    let uid = ""

                    await db.collection('users')
                        .where("merchantId", "==", mid)
                        .get()
                        .then((snapshots) => {
                            if (!snapshots.empty) {
                                snapshots.forEach(async (s) => {
                                    s = s.data()
                                    uid = s.uid
                                    userBasisPts = s.userBasisPts
                                    userType = s.userType
                                    approvedPts = s.approvedPts
                                })
                            }
                            else {
                                // console.log("Not found in users collection")
                            }
                        })
                    await db.collection('iso-temp-user')
                        .where("merchantId", "==", mid)
                        .get()
                        .then((snapshots) => {
                            if (!snapshots.empty) {
                                snapshots.forEach(async (s) => {
                                    s = s.data()
                                    uid = s.uid
                                    userBasisPts = s.userBasisPts
                                    userType = s.userType
                                    approvedPts = s.approvedPts
                                })
                            }
                            else {
                                // console.log("Not found in iso-temp-user collection")
                            }
                        })
                    await merchDb.doc(docId).collection("batch-summary").get()
                        .then((subSnapshot) => {
                            subSnapshot.docs.forEach((subDoc) => {
                                subDoc = subDoc.data()
                                if ((subDoc.approved == undefined) && (subDoc.denied == undefined)) {
                                    let merchantName = doc.data().merchantName
                                    let newObj = {
                                        ...subDoc,
                                        merchantName,
                                        parentDocId: docId,
                                        userBasisPts,
                                        approvedPts,
                                        contact,
                                        email,
                                        userType,
                                        uid
                                    }
                                    transCount += Number(subDoc.transactionCount)
                                    docArr.push(newObj)
                                }
                            })

                        })
                    count++
                    if (count === querySnapshot.docs.length) {
                        setMerchantLen(count)
                        docArr.sort((a, b) =>
                            new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
                        )
                        setTransactionLen(transCount)
                        let filter = docArr.filter((f) => f.contact !== "Bao Nguyen")
                        setCopyArr(filter)
                        setUsersData(filter)
                    }
                })
            })
    }

    const getUserData = () => {
        let combineArr = []
        let arr = []
        let tempArr = []
        let count = 0;
        let transCount = 0;
        const merchDb = db.collection('merchants-live')
        return db.collection('users')
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    snapshots.forEach((doc) => {
                        doc = doc.data()
                        db.collection('users')
                            .doc(doc.uid)
                            .collection('orders')
                            .get()
                            .then((orderSnapshot) => {
                                if (!orderSnapshot.empty) {
                                    let newObj = {}
                                    let orderArr = []
                                    orderSnapshot.forEach((orderDoc) => {
                                        orderDoc = orderDoc.data()
                                        orderArr.push(orderDoc)
                                    })
                                    newObj = {
                                        ...doc,
                                        orderArr
                                    }
                                    arr.push(newObj)
                                } else {
                                    arr.push(doc)
                                }
                            })
                    })

                }
                else {
                    setUsersData([])
                    setCopyArr([])
                }
                db.collection('temp-user').get()
                    .then((tempSnapshot) => {
                        if (!tempSnapshot.empty) {
                            tempSnapshot.forEach((tempDoc) => {
                                tempDoc = tempDoc.data()
                                tempArr.push(tempDoc)
                            })
                            combineArr = [...arr, ...tempArr]
                            const filtered = combineArr.sort((a, b) =>
                                (a.approvedPts != null ? a.approvedPts : -Infinity) - (b.approvedPts != null ? b.approvedPts : -Infinity)
                            )
                            setCopyArr(filtered)
                            setUsersData(filtered)
                        } else {
                            setUsersData([])
                            setCopyArr([])
                        }

                    })
            })
            .then(async () => {
                await merchDb.get()
                    .then(async (querySnapshot) => {
                        querySnapshot.docs.forEach(async (doc) => {
                            let docId = doc.data().docId
                            await merchDb.doc(docId).collection("batch-summary").get()
                                .then((subSnapshot) => {
                                    subSnapshot.docs.forEach((subDoc) => {
                                        subDoc = subDoc.data()
                                        if ((subDoc.approved == undefined) && (subDoc.denied == undefined)) {
                                            transCount += Number(subDoc.transactionCount)
                                        }
                                    })

                                })
                            count++
                            if (count === querySnapshot.docs.length) {
                                setMerchantLen(count)
                                setTransactionLen(transCount)
                            }
                        })
                    })
            })
    }

    useEffect(() => {
        if (!usersData) {
            // getData()
            getUserData()
        }
    }, [])

    useEffect(() => {
        if (usersData) {
            var arr = []
            setPoints([])
            usersData.map((d) => {
                const floorDollarPerBassisPts = ptsFormula(d.netAmount, d.userBasisPts)
                arr.push(floorDollarPerBassisPts)
                setPoints(prevArr => [...prevArr, floorDollarPerBassisPts])
            })
        }
        setZippedArr(zip(usersData, arr))
        setZippedCopyArr(zip(usersData, arr))
    }, [usersData])

    if (!usersData || !merchantLen)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <div>
                    <Typography variant="h2" className={classes.dashboardTitle} style={{ color: "#1A405F" }}>
                        Please View Page on Desktop / Laptop
                    </Typography >
                </div>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer style={{ marginTop: "-30px" }}>
                    <GridItem xs={3} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="h2" className={classes.dashboardTitle} style={{ paddingBottom: "10%" }}>
                            Dashboard
                        </Typography>
                        <Button
                            className={classes.addButton}
                            onClick={() => {
                                history.push('/admin/profile/add-user')
                            }}
                        >
                            <Add /> Add User
                        </Button>
                    </GridItem>
                    <GridItem xs={9} >
                        <Card className={classes.card} >
                            <GridContainer style={{ marginTop: "10px", marginBottom: "5px" }}>
                                <GridItem xs={2} >
                                    <AiOutlineDollar style={{ marginTop: "5px" }} />
                                </GridItem>
                                <GridItem xs={5}>
                                    Total Merchants
                                </GridItem>
                                <GridItem
                                    xs={5}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontWeight: 600,
                                        fontSize: "24px"
                                    }}
                                >
                                    {numberFormatter.format(merchantLen)}
                                </GridItem>
                            </GridContainer >
                            <div style={{ borderTop: "2px solid #FECD2C" }} />
                            <GridContainer style={{ marginTop: "15px" }}>
                                <GridItem xs={2}>
                                    <AiOutlineDollar style={{ marginTop: "5px" }} />
                                </GridItem>
                                <GridItem xs={5}>
                                    Total Transactions
                                </GridItem>
                                <GridItem xs={5}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontWeight: 600,
                                        fontSize: "24px"
                                    }}>
                                    {numberFormatter.format(transactionLen)}
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                    {/* <GridItem xs={7} style={{ marginBottom: "0px" }}>
                        <Card className={classes.card}>
                            <GridContainer style={{ marginTop: "20px", marginBottom: "0px" }}>
                                <GridItem xs={3} style={{ marginBottom: "0px" }}>
                                    <p className={classes.circleChartDesc}>
                                        Product Popularity by {"\n"}Category
                                    </p>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 50,
                                                },
                                                {
                                                    label: '',
                                                    value: 50,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Luxury{"\n"}Goods{"\n"}50%
                                        </p>
                                    </div>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 30,
                                                },
                                                {
                                                    label: '',
                                                    value: 100 - 30,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Experiences{"\n"}Goods{"\n"}30%
                                        </p>
                                    </div>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 15,
                                                },
                                                {
                                                    label: '',
                                                    value: 100 - 15,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Luxury{"\n"}Goods{"\n"}15%
                                        </p>
                                    </div>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 10,
                                                },
                                                {
                                                    label: '',
                                                    value: 100 - 10,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Other{"\n"}Goods{"\n"}10%
                                        </p>
                                    </div>
                                </GridItem>
                            </GridContainer >
                        </Card>
                    </GridItem> */}
                    <GridItem xs={12}>
                        <Card className={classes.cardTable} style={{ marginTop: "0px" }}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Filter
                                        classes={classes}
                                        rewardsSwitch={rewardsSwitch}
                                        setRewardsSwitch={setRewardsSwitch}
                                        statusDropdown={statusDropdown}
                                        setStatusDropdown={setStatusDropdown}
                                        ascDropdown={ascDropdown}
                                        setAscDropdown={setAscDropdown}
                                        searchString={searchString}
                                        setSearchString={setSearchString}
                                        listSwitch={listSwitch}
                                        setListSwitch={setListSwitch}
                                        statusArr={statusArr}
                                        ascArr={ascArr}
                                        handleRewardsToggle={handleRewardsToggle}
                                        handleListToggle={handleListToggle}
                                        handleFilter={handleFilter}
                                        handleSearch={handleSearch}
                                        userTypeModal={userTypeModal}
                                        setUserTypeModal={setUserTypeModal}
                                        usersData={usersData}
                                        zippedArr={zippedArr}
                                        userTypeArr={userTypeArr}
                                        handleUserTypeFilter={handleUserTypeFilter}
                                        userTypeArrFilter={userTypeArrFilter}
                                        userHeaders={userHeaders}
                                        listHeaders={listHeaders}
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <Table
                                        history={history}
                                        data={zippedArr}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        index={index}
                                        setIndex={setIndex}
                                        endIndex={endIndex}
                                        setEndIndex={setEndIndex}
                                        itemsPerPage={itemsPerPage}
                                        handlePageClick={handlePageClick}
                                        listSwitch={listSwitch}
                                        setApproveModal={setApproveModal}
                                        setPtsToApprove={setPtsToApprove}
                                        setMerchantContact={setMerchantContact}
                                        setEmail={setEmail}
                                        setBatchInfo={setBatchInfo}
                                    />
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Dialog
                className={classes.approvedModal}
                open={approveModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <h5 className={classes.cardHeaderModal}>Approve Pending Points</h5>
                            <Button
                                onClick={() => handleApprove()}
                                className={classes.modalButton}
                                style={{ background: "#56CA93", }}>
                                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Send
                            </Button>

                            <Button
                                onClick={() => handleDenied()}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Deny
                            </Button>

                            <Button
                                onClick={() => resetModal()}
                                style={{ background: "#1A405F", }}
                                className={classes.modalButton}>
                                <ArrowBackIos style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                            </Button>
                        </GridItem>

                        <GridItem xs={2} className={classes.gridItemMarginTop}>
                            <Typography
                                // style={errors.points ? { marginTop: "30px" } : {}}
                                className={classes.modalCardDesc}>
                                Points
                            </Typography>
                        </GridItem>

                        <GridItem xs={2} className={classes.gridItemMarginTop}>
                            <div>
                                <Typography className={classes.modalCardDesc}>
                                    {ptsToApprove}
                                </Typography>
                            </div>
                        </GridItem>

                        <GridItem xs={8} className={classes.gridItemMarginTop}>
                            <GridContainer>
                                <GridItem xs={3}>
                                    <Typography
                                        className={classes.modalCardDesc}>
                                        Merchant
                                    </Typography>
                                </GridItem>
                                <GridItem xs={9}>
                                    <Typography className={classes.modalCardDesc}>
                                        {merchantContact[0] + merchantContact[1] + " " + email}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>


                        <GridItem xs={2} className={classes.gridItemMarginTop}>
                            <Typography
                                className={classes.modalCardDesc}>
                                Message (Optional)
                            </Typography>
                        </GridItem>


                        <GridItem xs={10} className={classes.gridItemMarginTop}>
                            <TextareaAutosize
                                style={{ height: "55px", paddingTop: "10px", color: "#1A405F", }}
                                className={classes.modalTextField}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        input: classes.input
                                    },
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                            />
                            {/* </div> */}
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>

            <Dialog
                className={classes.approvedModal}
                open={confirmed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard} style={{ width: "520px" }}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <h5 className={classes.cardHeaderModal}>Pending Points Approved</h5>
                            <Button
                                onClick={() => {
                                    setConfirmed(false)
                                    getUserData();
                                    resetModal();
                                }}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Exit
                            </Button>
                        </GridItem>

                        <GridItem xs={12} className={classes.gridItemMarginTop}>
                            <Typography
                                className={classes.modalCardDesc}>
                                {ptsToApprove} points will be sent to {merchantContact[0] + merchantContact[1]} as soon as possible
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>

            <Dialog
                className={classes.approvedModal}
                open={denied}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard} style={{ width: "520px" }}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <h5 className={classes.cardHeaderModal}>Pending Points Denied</h5>
                            <Button
                                onClick={() => {
                                    setDenied(false)
                                    getUserData();
                                    resetModal();
                                }}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Exit
                            </Button>
                        </GridItem>

                        <GridItem xs={12} className={classes.gridItemMarginTop}>
                            <Typography className={classes.modalCardDesc}>
                                {merchantContact[0] + merchantContact[1]}'s {ptsToApprove} pending points have been denied
                            </Typography>
                        </GridItem>

                        <GridItem xs={12} className={classes.gridItemMarginTop}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <Typography className={classes.modalCardDesc} style={{ fontWeight: 700 }}>
                                    Message:
                                </Typography>
                                <Typography className={classes.modalCardDesc} style={{ marginLeft: "5px" }}>
                                    {message}
                                </Typography>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>

            <Dialog
                className={classes.approvedModal}
                open={userTypeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard} style={{ width: "520px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Typography className={classes.cardHeaderModal}>Select Which User Type You Wish To View</Typography>
                        </GridItem>

                        <GridItem xs={12} className={classes.gridItemMarginTop}>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <GridContainer>
                                        <GridItem xs={12} >
                                            <FormControlLabel
                                                key={index}
                                                classes={{
                                                    label: classes.checkboxes
                                                }}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            style={{ color: "#1A405F" }}
                                                            checked={userTypeArrFilter.length === userTypeArr.length}
                                                            onChange={() => {
                                                                setUserSelectAll(!userSelectAll)
                                                                handleSelectAll()
                                                            }}
                                                            value={userTypeArrFilter.length === userTypeArr.length}
                                                        />

                                                    </>
                                                }
                                                value={userTypeArrFilter.length === userTypeArr.length}
                                                label={"All"}
                                            />
                                        </GridItem>
                                        {userTypeArr.map((data, index) => {
                                            return (
                                                <GridItem xs={12} >
                                                    <FormControlLabel
                                                        key={index}
                                                        classes={{
                                                            label: classes.checkboxes
                                                        }}
                                                        control={
                                                            <>
                                                                <Checkbox
                                                                    style={{ color: "#1A405F" }}
                                                                    checked={userTypeArrFilter.includes(data)}
                                                                    onChange={() => handleUserTypeFilter(data)}
                                                                />

                                                            </>
                                                        }
                                                        value={data}
                                                        label={data}
                                                    />
                                                </GridItem>
                                            )
                                        })}
                                    </GridContainer>
                                </FormGroup>
                            </FormControl>

                        </GridItem>

                        <GridItem xs={12} >
                            <Button
                                onClick={() => {
                                    setUserTypeModal(false)
                                    handleFilter("Asc", "User Type");
                                }}
                                className={classes.modalButtonUserType}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Select
                            </Button>
                        </GridItem>

                    </GridContainer>
                </Card>
            </Dialog>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userType: state.auth.user.userType
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(DashboardPage));
