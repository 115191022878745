/*eslint-disable*/
import React, { useParams, useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from 'assets/img/market-me/settingsLogo.png'
import SelectedSettingsLogo from 'assets/img/market-me/selectedSettingsLogo.png'



// core components
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import AuthActions from 'store/actions/auth';
import { setPathname } from 'store/actions/windowActions'
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MerchantCustomDropdownMobile from "components/CustomDropdown/MerchantCustomDropdownMobile";

import tuzoLogo from "assets/img/tuzo/tuzoHorizontal.png"
import { ReactComponent as CartIcon } from "assets/img/tuzo/icons/CartIcon.svg";
import { ReactComponent as CartIconWhite } from "assets/img/tuzo/icons/CartIconWhite.svg";
import { ReactComponent as Calculator } from "assets/img/tuzo/icons/calculator.svg";
import IsoAdminDropdown from "components/CustomDropdown/IsoAdminDropdown";
import IsoMerchantDropdown from "components/CustomDropdown/IsoMerchantDropdown";



const merchantRoutesMobile = [
  { name: "Rewards", link: "/merchant/rewards", },
]



const useStyles = makeStyles(styles);

export function AdminNavbar(props) {
  const classes = useStyles();
  const {
    color,
    rtlActive,
    brandText,
    routes,
    history,
    user,
    globalCalulcatorOpen,
    setGlobalCalulcator,
    globalArrowOpen,
    setGlobalArrow,
    cart,
    userCompany,
    userMerchant,
    isoLogo
  } = props;
  const [clickDropdown, setClickDropdown] = useState(false);

  let company = userCompany?.replace(/ /g, '').toLowerCase()
  let merchantName = userMerchant?.replace(/ /g, '').toLowerCase()

  const merchantRoutes = [
    { name: "Activity", link: `/iso-merchant/${company}/${merchantName}/activity` },
    { name: "Rewards", link: `/iso-merchant/${company}/${merchantName}/rewards`, },
    { name: "Travel", link: `/iso-merchant/${company}/${merchantName}/travel` },
    //   { name: "Orders", link: "/merchant/orders" },
    { name: "Reports", link: `/iso-merchant/${company}/${merchantName}/reports/batch` },
  ]

  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  const [pathName, setPathName] = useState(window.location.pathname)
  const path = window.location.pathname

  useEffect(() => {
    setPathName(path)
  }, [path])


  return (
    <div>
      <Hidden mdUp implementation="css">
        <AppBar id="merchant-navbar" className={classes.appBarMobile + appBarClasses}>
          <Toolbar className={classes.container} style={{ display: "block" }}>
            <GridContainer className={classes.sixtyPixelsMobile}>
              <GridItem className={classes.gridItemHover} xs={6}
                onClick={() => {
                  history.push(`/iso-merchant/${company}/${merchantName}/activity`)
                  setGlobalCalulcator(false)
                }}
                style={{ marginTop: "-5px" }}
              >
                <img src={tuzoLogo} className={classes.logoImg} />
                {/* <span className={classes.notificationsCount}>
                  {" "}
                  4
                </span> */}
              </GridItem>
              <GridItem xs={2}>
                <GridContainer className={classes.routesGridContainerMobile}>
                  <GridItem xs={12} className={classes.gridItemNoPadding}>
                    {
                      merchantRoutesMobile.map((r) => {
                        return (
                          <GridItem
                            onClick={() => {
                              history.push(r.link)
                              setGlobalCalulcator(false)
                            }}
                            className={
                              window.location.pathname === r.link
                                ? classes.routesGridItemUnderlineMobile
                                : classes.routesGridItem
                            }
                            key={r.name}
                            xs={7}
                          >
                            <span className={classes.headerLinksText}>{r.name}</span>
                          </GridItem>
                        );
                      })
                    }

                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={2} className={classes.gridItemNoPadding}>
                <GridContainer className={classes.iconsGridContainerMobile} >
                  <GridItem xs={12}
                    onClick={() => {
                      setGlobalCalulcator(!globalCalulcatorOpen)
                      // history.push('/auth/calculator')
                      history.push(`/iso-merchant/${company}/${merchantName}/cart`)
                    }}
                    className={
                      !globalCalulcatorOpen
                        ? classes.iconGridItemMobile
                        : classes.iconGridItemTrueMobile
                    }
                  >
                    <CartIcon style={{}} />
                    <div>
                      <span className={classes.cartCountMobile}>
                        {" "}
                        {cart.length}
                      </span>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem
                xs={2}
                className={classes.gridItemNoPadding}
              >
                <MerchantCustomDropdownMobile
                  setClickDropdown={setClickDropdown}
                  clickDropdown={clickDropdown}
                  adminNavbar
                  history={history}
                  dropdownList={
                    [
                      "Activity",
                      "Rewards",
                      // "Travel",
                      "Orders",
                      "Reports",
                      "Profile",
                      "Request Assistance",
                      { divider: true },
                      "Log Out",
                    ]}
                />
              </GridItem>

            </GridContainer>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden className={classes.hiddenSmall} smDown implementation="css">
        <AppBar id="merchant-navbar" className={classes.appBar + appBarClasses}>
          <Toolbar className={classes.container}>
            <GridContainer className={classes.sixtyPixels}>
              <GridItem className={classes.gridItemHover} xs={2}
                onClick={() => {
                  history.push(`/iso-merchant/${company}/${merchantName}/activity`)
                  setGlobalCalulcator(false)
                }}
              >
                <img src={isoLogo ? isoLogo : tuzoLogo} style={{ height: "35px" }} />
                {/* <span className={classes.notificationsCount}>
                  {" "}
                  4
                </span> */}
              </GridItem>
              <GridItem className={classes.gridItem} xs={6}>
                <GridContainer className={classes.routesGridContainer}>
                  <GridItem xs={12} className={classes.gridItem}>
                    {
                      merchantRoutes.map((r) => {
                        return (
                          <GridItem
                            onClick={() => {
                              history.push(r.link)
                              setGlobalCalulcator(false)
                            }}
                            className={
                              window.location.pathname === r.link
                                ? classes.routesGridItemUnderline
                                : classes.routesGridItem
                            }
                            key={r.name}
                            xs={2}
                          >
                            <span className={classes.headerLinksText}>{r.name}</span>
                          </GridItem>
                        );
                      })
                    }

                  </GridItem>
                </GridContainer>
              </GridItem>


              <GridItem xs={3} className={classes.gridItemSpaceBetween} style={{ background: "#06667C", }}>
                <div>
                  <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
                    <p className={classes.name}>
                      {user.firstName ? user.firstName + " " + user.lastName + "   " : ""}
                    </p>
                    <p className={classes.name} style={{ marginLeft: "10px", fontWeight: 700, fontSize: "21px", lineHeight: "21px" }}>
                      {user.approvedPts ? Number(user.approvedPts).toLocaleString() : 0}
                    </p>
                    <p className={classes.name} style={{ marginLeft: "10px", fontSize: "14px", marginTop: "4px" }}>
                      Points
                    </p>
                  </div>
                </div>
                <IsoMerchantDropdown
                  setClickDropdown={setClickDropdown}
                  clickDropdown={clickDropdown}
                  adminNavbar
                  history={history}
                  dropdownList={
                    [
                      "Profile",
                      // "Show More Accounts",
                      "Request Assistance",
                      { divider: true },
                      "Log Out",
                    ]}
                />
              </GridItem>

              <GridItem xs={1} className={classes.gridItemNoPadding}>
                <GridContainer className={classes.iconsGridContainer} >
                  <GridItem xs={12}
                    onClick={() => {
                      setGlobalCalulcator(!globalCalulcatorOpen)
                      if (!globalCalulcatorOpen) {
                        history.push(`/iso-merchant/${company}/${merchantName}/cart`)

                      }
                      else {
                        history.push(`/iso-merchant/${company}/${merchantName}/activity`)
                      }

                    }}
                    className={
                      !globalCalulcatorOpen
                        ? classes.iconGridItem
                        : classes.iconGridItemTrue
                    }
                  >
                    {!globalCalulcatorOpen ?
                      <CartIcon style={{ marginRight: "25px" }} />
                      :
                      <CartIconWhite style={{ marginRight: "25px" }} />
                    }
                    <div>
                      <span className={classes.cartCount}>
                        {" "}
                        {cart.length}
                      </span>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
          </Toolbar >
        </AppBar >
      </Hidden>
    </div>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};


function mapStateToProps(state) {
  return {
    userMerchant: state.auth.user.companyName,
    userCompany: state.auth.user.IsoAdminCompanyName,
    user: state.auth.user,
    cart: state.cart.cart,
    isoLogo: state.auth.user.logo
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.startLogout()),
  setPathname: (path) => dispatch(setPathname(path))
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AdminNavbar));
