import Dashboard from "views/Pages/isostaffdashboard/Dashboard";
import Rewards from "views/Pages/isoadminreward/Rewards";
import NewProfile from "views/Pages/isostaffnewprofile/NewProfile";

var isoStaffRoutes = [
    {
        path: '/:iso/merchants',
        name: 'Merchants',
        component: Dashboard,
        layout: '/iso-staff',
      },
      {
        path: '/:iso/rewards',
        name: 'Merchants',
        component: Rewards,
        layout: '/iso-staff',
      },
      {
        path: '/:iso/add-merchant',
        name: 'Add Merchant',
        component: NewProfile,
        layout: '/iso-staff',
      },
]

export default isoStaffRoutes;