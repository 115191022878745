// /*eslint-disable*/
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Recaptcha from "react-google-recaptcha"
import { db } from 'firebase/client';
import loader from "assets/img/loader.gif";
import { sendReferral } from 'firebase/client';

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/referralStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Button, Card, Hidden, IconButton, MenuItem, Select, TextField, TextareaAutosize, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Close } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';


const userTypeArr = [
    "Merchants",
    "IsoAdmin",
    "Referral"
]

export function RegisterPage(props) {
    const { classes, history } = props;
    const [newTempPw, setNewTempPw] = useState("")
    const [tempUser, setTempUser] = useState(false)
    const [checkCap, setCheckCap] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [companyName, setCompanyName] = useState("")
    const [isoLogo, setIsoLogo] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("");
    const [userType, setUserType] = useState("Merchants")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [notes, setNotes] = useState("")
    const [merchantId, setMerchantId] = useState("")
    const [checkForNext, setCheckForNext] = useState(false)
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        companyName: false,
    })



    useEffect(() => {
        if (checkCap && merchantId) {
            setCheckForNext(true)
        }
    }, [checkCap, merchantId])


    const sendForm = async (e) => {
        e.preventDefault();
        if (!firstName || !lastName || !email || !companyName) {
            let newErrs = {
                firstName: false,
                lastName: false,
                email: false,
                companyName: false,
            }
            if (!firstName) {
                newErrs = { ...newErrs, firstName: true }
            }
            if (!lastName) {
                newErrs = { ...newErrs, lastName: true }
            }
            if (!email) {
                newErrs = { ...newErrs, email: true }
            }
            if (!companyName) {
                newErrs = { ...newErrs, companyName: true }
            }
            setErrors({ ...newErrs })
        }
        else {
            const docId = db.collection('referral').doc().id
            const data = {
                firstName,
                lastName,
                email,
                companyName,
                phoneNumber,
                userType,
                notes
            }
            await db
                .collection('referral')
                .doc(docId)
                .set(data)
                .then(() => {
                    sendReferral(data)
                })
                .then(() => {
                    window.location.href= "/auth/login-page"
                })
        }
    }

    return (
        <div style={{ overflow: "auto" }}>
            <Hidden mdUp implementation='css'>
                <GridContainer>
                    {/* <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <img className={classes.logoImg} src={isoLogo} />
                    </GridItem> */}

                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <Card className={classes.mobileIsoMainContainer}>
                            <CardHeader className={classes.cardHeaderTitle}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Referral
                                    </GridItem>

                                    <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                                        <IconButton
                                            style={{ color: "#004253", marginTop: "-15px" }}
                                            onClick={() => history.push('/auth/login-page')}
                                        >
                                            <Close />
                                        </IconButton>
                                    </GridItem>
                                    {/* <GridItem xs={12}
                                        style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
                                    >
                                        Step 1 of 2
                                    </GridItem> */}
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
                                <GridContainer>
                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            First Name*
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={firstName}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        {errors.firstName ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Last Name*
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setLastName(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={lastName}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        {errors.lastName ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Email*
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setEmail(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={email}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        {errors.email ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Company Name*
                                        </Typography>
                                        <TextField
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            className={classes.textFieldMobile}
                                            value={companyName}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        {errors.companyName ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Phone Number
                                        </Typography>
                                        <TextField
                                            disabled
                                            className={classes.textFieldMobile}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            User Type*
                                        </Typography>
                                        <Select
                                            style={{ width: "100%", marginTop: "0px" }}
                                            className={classes.selectMenuSortProfile}
                                            value={userType}
                                            disableUnderline
                                            classes={{
                                                root: classes.selectText,
                                            }}
                                            onChange={(e) => {
                                                setUserType(e.target.value)
                                            }}
                                        >
                                            {userTypeArr.map((o, i) => {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        value={o}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                        }}
                                                    >
                                                        {o}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Notes
                                        </Typography>
                                        <TextareaAutosize
                                            aria-label="empty textarea"
                                            className={classes.textFieldMobile}
                                            onChange={(e) => setNotes(e.target.value)}
                                            placeholder=""
                                            style={{ color: "#1A405F", maxWidth: "100%", minHeight: "100px", height: "50px", paddingTop: "10px" }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Button
                                            className={classes.addIdButtonMobile}
                                            style={{ background: "#56CA93", width: "100%" }}
                                            onClick={sendForm}
                                        >
                                            Send Out Form
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div >
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                                >
                                    Terms of Use
                                </span>
                            </div>

                            <div>
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                >
                                    Privacy Policy
                                </span>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </Hidden >


            <Hidden smDown implementation='css'>
                <GridContainer>
                    {/* <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <img className={classes.logoImg} src={isoLogo} />
                    </GridItem> */}

                    <GridItem xs={12} className={classes.gridItemFlexCenter} style={{ marginTop: "10px" }}>
                        <Card className={classes.isoMainContainer}>
                            <CardHeader className={classes.cardHeaderTitle}>
                                <GridContainer>
                                    <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                        Referral
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            First Name*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <TextField
                                                onChange={(e) => setFirstName(e.target.value)}
                                                className={classes.textField}
                                                value={firstName}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                        {errors.firstName ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            Last Name*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <TextField
                                                onChange={(e) => setLastName(e.target.value)}
                                                className={classes.textField}
                                                value={lastName}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                        {errors.lastName ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            Email*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <TextField
                                                onChange={(e) => setEmail(e.target.value)}
                                                className={classes.textField}
                                                value={email}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                        {errors.email ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>



                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            Company Name*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <TextField
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                className={classes.textField}
                                                value={companyName}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                        {errors.companyName ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            Phone Number
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <TextField
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                className={classes.textField}
                                                value={phoneNumber}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            User Type*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <Select
                                                style={{ paddingTop: "5px" }}
                                                className={classes.selectMenuSortProfile}
                                                value={userType}
                                                disableUnderline
                                                classes={{
                                                    root: classes.selectText,
                                                }}
                                                onChange={(e) => {
                                                    setUserType(e.target.value)
                                                }}
                                            >
                                                {userTypeArr.map((o, i) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            value={o}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                            }}
                                                        >
                                                            {o}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            Notes
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8}>
                                        <div>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                className={classes.textField}
                                                onChange={(e) => setNotes(e.target.value)}
                                                placeholder=""
                                                style={{ color: "#1A405F", maxWidth: "100%", minHeight: "100px", height: "50px", paddingTop: "10px" }}
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            className={classes.addIdButton}
                                            style={{ background: "#56CA93", marginLeft: "15px" }}
                                            onClick={sendForm}
                                        >
                                            Send Out Form
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div >
                                <span className={classes.referralTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                                >
                                    Terms of Use
                                </span>
                            </div>

                            <div>
                                <span className={classes.referralTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                >
                                    Privacy Policy
                                </span>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </Hidden >
        </div >
    )

}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RegisterPage));