/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import UserInfoMobile from "./components/UserInfoMobile";
import BillingInfoMobile from "./components/BillingInfoMobile";
import { updateTempUserData, updateTempUserPhoto, updateUserLogo, deleteTempUserPhoto } from "store/actions/auth.js";
import { sendIsoAdminSignUpLinkLive, sendSignUpLinkLive, sendAdminSignUpLinkLive } from "firebase/client";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Dialog, Typography, Card } from "@material-ui/core";



// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { BsCheckCircleFill } from "react-icons/bs";
import { Close } from '@material-ui/icons';

//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import loader from "assets/img/loader.gif";




export function TempUserProfile(props) {
    const {
        classes,
        history,
        updateTempUserDate,
        firstName,
        lastName,
        email,
        cellPhone,
        secondaryPhone,
        shippingAddress,
        birthday,
        companyName,
        uid,
        user,
        userInfo,
        authInfo,
        usernameState,
        editState
    } = props;

    const currUser = firebase.auth().currentUser
    const docId = window.location.pathname.split("/")[3]
    const [dataLoaded, setDataLoaded] = useState(false)
    const [merchantData, setMerchantData] = useState(false)
    const [username, setUsername] = useState("")
    const [firstNameState, setFirstNameState] = useState("")
    const [lastNameState, setLastNameState] = useState("")
    const [emailState, setEmailState] = useState("")
    const [copyEmail, setCopyEmail] = useState("")
    const [cellPhoneState, setCellPhoneState] = useState("")
    const [secondaryPhoneState, setSecondaryPhoneState] = useState("")
    const [shippingAddressState, setShippingAddressState] = useState("")
    const [companyState, setCompanyState] = useState("");
    const [birthdayState, setBirthdayState] = useState("")
    const [approvedPtsState, setApprovedPtsState] = useState(0)
    const [profilePic, setProfilePic] = useState("")
    const [progress, setProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [globalEdit, setGlobalEdit] = useState(editState ? editState : false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [billingEdit, setBillingEdit] = useState(false)
    const [cardNumber, setCardNumber] = useState("")
    const [expDate, setExpDate] = useState("")
    const [nameOnCard, setNameOnCard] = useState("")
    const [billingAddress, setBillingAddress] = useState("")
    const [asDefault, setAsDefault] = useState(true)
    const [newPayment, setNewPayment] = useState(false)
    const [typeOfPayment, setTypeOfPayment] = useState({
        card: true,
        ach: false
    })

    useEffect(() => {
        if (progress === 100) {
            setUploading(false);
        }
    }, [progress]);

    function formatPhoneNumber(phoneNumber) {
        var cleaned = ("" + phoneNumber).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return "";
    }
    const updateUserInfo = (e) => {
        e.preventDefault();
        var newInfo = {
            username: username,
            firstName: firstNameState,
            lastName: lastNameState,
            companyName: companyState,
            birthday: birthdayState,
            email: emailState,
            cellPhone: cellPhoneState,
            secondaryPhone: secondaryPhoneState,
            shippingAddress: shippingAddressState,
        };
        props.updateTempUserData(docId, newInfo).then(() => {
            setGlobalEdit(false)
        })
    };

    const handleImageUpload = (event) => {
        var previewUrl = URL.createObjectURL(event.target.files[0]);
        var file = event.target.files[0];
        const storageRef = storage.ref(uid);
        const fileRef = storageRef.child("profilePic");

        var uploadTask = fileRef.put(file);

        // fileRef.put(file).then((snapshot) => {
        setUploading(true)

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            (snapshot) => {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then((downloadURL) => {
                        console.log("downloadURL", downloadURL);
                        setProfilePic(downloadURL)
                        props.updateTempUserPhoto(docId, { url: downloadURL, });
                    })
                    .catch(() => { });
            }
        );
    };

    const getBgImage = () => {
        return tuzoBg;
    }

    const deleteImage = () => {
        props.deleteTempUserPhoto(docId)
        setProfilePic("")
        setConfirmDelete(false)
    }

    const handleResendEmail = (e) => {
        e.preventDefault()
        let tempData = {
            userType: merchantData.userType,
            username,
            firstName: firstNameState,
            lastName: lastNameState,
            companyName: companyState,
            birthday: birthdayState,
            email: emailState,
            cellPhone: cellPhoneState,
            secondaryPhone: secondaryPhoneState,
            shippingAddress: shippingAddressState,
            id: docId,
        }
        if (merchantData.userType === ("Merchant")) {
            sendSignUpLinkLive(tempData)
            setEmailSent(true)
        }
        else if (merchantData.userType === "Administrator") {
            sendAdminSignUpLinkLive(tempData)
            setEmailSent(true)
        }
        else {
            sendIsoAdminSignUpLinkLive(tempData)
            setEmailSent(true)
        }
    }

    const getData = () => {
        db.collection('temp-user').doc(docId).get()
            .then((snapshots) => {
                if (snapshots.exists) {
                    let data = snapshots.data()
                    setMerchantData(data)
                    setUsername(data.username ? data.username : "")
                    setFirstNameState(data.firstName ? data.firstName : "")
                    setLastNameState(data.lastName ? data.lastName : "")
                    setEmailState(data.email ? data.email : "")
                    setCellPhoneState(data.cellPhone ? data.cellPhone : "")
                    setSecondaryPhoneState(data.secondaryPhone ? data.secondaryPhone : "")
                    setShippingAddressState(data.shippingAddress ? data.shippingAddress : "")
                    setCompanyState(data.companyName ? data.companyName : "")
                    setBirthdayState(data.birthday ? data.birthday : "")
                    setApprovedPtsState(data.approvedPts ? data.approvedPts : 0)
                    setProfilePic(data.profilePic ? data.profilePic : "")
                } else {
                    console.log("checking empty")
                }

            })
            .then(() => {
                setDataLoaded(true)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    if (!dataLoaded)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );

    return (
        <div>
            <Hidden smDown implementation="css">
                <GridContainer >
                    <GridItem xs={8} style={{ display: "flex" }}>
                        <Typography variant="h2" className={classes.headerTitle}>
                            {firstNameState + " " + lastNameState + "  "}
                        </Typography>
                        {billingEdit && !globalEdit ?
                            null
                            :
                            !globalEdit ?
                                <>
                                    <Button
                                        onClick={() => setGlobalEdit(true)}
                                        className={classes.editButton}
                                    >
                                        <Edit style={{ marginRight: "10px", marginTop: "-5px" }} /> Edit
                                    </Button>

                                    <Button
                                        onClick={handleResendEmail}
                                        className={classes.editButton}
                                        style={{ background: "#56CA93", width: "350px" }}
                                    >
                                        <BsCheckCircleFill style={{ marginRight: "10px", marginTop: "-5px" }} /> Send Verification Email
                                    </Button>
                                </>
                                :
                                <div>
                                    <Button
                                        className={classes.editButton}
                                        style={{ background: "#1A405F", width: "128px" }}
                                        onClick={updateUserInfo}
                                    >
                                        <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                                    </Button>
                                    <Button
                                        onClick={() => setGlobalEdit(false)}
                                        className={classes.editButton}
                                        style={{ background: "#E2A400", width: "143px" }}
                                    >
                                        <Delete style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                                    </Button>
                                </div>
                        }
                    </GridItem>

                    <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>

                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
                            <Typography variant="h2" className={classes.headerDesc}>
                                Account Number
                            </Typography>
                            <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                                {docId}
                            </Typography>
                        </div>

                    </GridItem>
                </GridContainer>

                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "130px",
                        marginTop: "15px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "no-repeat",
                        backgroundSize: "contain"
                    }}
                >
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
                            <Typography variant="h2" className={classes.profileBannerText}>
                                You have <span className={classes.profileBannerPts}> {(approvedPtsState).toLocaleString()} </span> Tuzo points now!
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ textAlign: "center" }}>
                            <Typography variant="h2" className={classes.profileBannerText} style={{ textAlign: "left", lineHeight: "30px" }}>
                                Based on your average monthly processing ({(approvedPtsState).toLocaleString()}), you’re on track to have:
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", justifyContent: "space-evenly", textAlign: "center" }}>

                            <div>
                                <Typography variant="h2" className={classes.profileBannerDurationText}>
                                    1 Month:
                                </Typography>
                                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                                    {(approvedPtsState.toLocaleString())}
                                </Typography>
                            </div>

                            <div>
                                <Typography variant="h2" className={classes.profileBannerDurationText}>
                                    1 Year:
                                </Typography>
                                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                                    {(approvedPtsState * 12).toLocaleString()}
                                </Typography>
                            </div>

                            <div>
                                <Typography variant="h2" className={classes.profileBannerDurationText}>
                                    3 Years:
                                </Typography>
                                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                                    {(approvedPtsState * 36).toLocaleString()}
                                </Typography>
                            </div>
                        </GridItem>

                    </GridContainer>
                </div>

                <GridContainer >
                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <UserInfo
                            username={username}
                            setUsername={setUsername}
                            user={user}
                            profilePic={profilePic}
                            firstNameState={firstNameState}
                            setFirstNameState={setFirstNameState}
                            lastNameState={lastNameState}
                            setLastNameState={setLastNameState}
                            companyState={companyState}
                            setCompanyState={setCompanyState}
                            birthdayState={birthdayState}
                            setBirthdayState={setBirthdayState}
                            emailState={emailState}
                            setEmailState={setEmailState}
                            cellPhoneState={cellPhoneState}
                            setCellPhoneState={setCellPhoneState}
                            secondaryPhoneState={secondaryPhoneState}
                            setSecondaryPhoneState={setSecondaryPhoneState}
                            shippingAddressState={shippingAddressState}
                            setShippingAddressState={setShippingAddressState}
                            globalEdit={globalEdit}
                            handleImageUpload={handleImageUpload}
                            uploading={uploading}
                            progress={progress}
                            deleteImage={deleteImage}
                            formatPhoneNumber={formatPhoneNumber}
                            confirmDelete={confirmDelete}
                            setConfirmDelete={setConfirmDelete}
                        />
                    </GridItem>

                    <GridItem xs={12} >
                        <BillingInfo
                            globalEdit={globalEdit}
                            billingEdit={billingEdit}
                            setBillingEdit={setBillingEdit}
                            cardNumber={cardNumber}
                            setCardNumber={setCardNumber}
                            expDate={expDate}
                            setExpDate={setExpDate}
                            nameOnCard={nameOnCard}
                            setNameOnCard={setNameOnCard}
                            billingAddress={billingAddress}
                            setBillingAddress={setBillingAddress}
                            asDefault={asDefault}
                            setAsDefault={setAsDefault}
                            newPayment={newPayment}
                            setNewPayment={setNewPayment}
                            typeOfPayment={typeOfPayment}
                            setTypeOfPayment={setTypeOfPayment}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Dialog
                className={classes.requestModal}
                open={emailSent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card className={classes.confirmModalCard}>
                    <GridContainer >
                        <GridItem xs={12} >
                            <h5 className={classes.cardHeader}>Email verification has been resent to user. </h5>
                        </GridItem>
                        <GridItem xs={12} style={{ justifyContent: "center", display: "flex" }}>
                            <Button
                                className={classes.uploadButton}
                                onClick={() => {
                                    setEmailSent(false)
                                    history.push('/admin/dashboard')
                                }}

                            >
                                <Close style={{ marginRight: "10px" }} />  Close
                            </Button>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>
        </div>
    );
}

TempUserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, location) {
    return {
        editState: location.history.location.state?.editState

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
            ),
        updateTempUserData: (uid, newInfo) => dispatch(updateTempUserData(uid, newInfo)),
        updateTempUserPhoto: (id, document) => dispatch(updateTempUserPhoto(id, document)),
        deleteTempUserPhoto: (id) => dispatch(deleteTempUserPhoto(id)),
        updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
        startPasswordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(TempUserProfile);
