import React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// core components
import { InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import { Typography } from '@material-ui/core';

// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add, Search } from '@material-ui/icons';
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";

// styling 
import Style from 'assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';

export function Filter(props) {
    const {
        classes,
        history,
        rewardsSwitch,
        setRewardsSwitch,
        statusDropdown,
        setStatusDropdown,
        ascDropdown,
        setAscDropdown,
        searchString,
        setSearchString,
        listSwitch,
        setListSwitch,
        statusArr,
        ascArr,
        itemsPerPage,
        setItemsPerPage,
        entriesArr,
        handleSearch,
        csvData,
        headers,
        setEndIndex
    } = props;
    return (
        <div>
            <GridContainer>
                <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                    <Typography className={classes.sortByTypography}>
                        Sort By
                    </Typography>
                    <Select
                        className={classes.selectMenuSort}
                        value={statusDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => setStatusDropdown(e.target.value)}
                    >
                        {statusArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        className={classes.selectAsc}
                        value={ascDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => setAscDropdown(e.target.value)}
                    >
                        {ascArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </GridItem>

                <GridItem xs={5}>
                    <TextField
                        className={classes.searchBar}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.input
                            },
                            endAdornment: (
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button
                                        className={classes.searchButton}
                                        onClick={() => handleSearch()}
                                    >
                                        <Search style={{ marginRight: "5px" }} />
                                        Search
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>

                <GridItem xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Typography className={classes.sortByTypography}>
                        Show
                    </Typography>

                    <Select
                        className={classes.selectAsc}
                        value={itemsPerPage}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setItemsPerPage(e.target.value)
                            setEndIndex(e.target.value)
                        }}
                    >
                        {entriesArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Typography className={classes.sortByTypography}>
                        Entries
                    </Typography>
                    {/* <Button
                            className={classes.csvButton}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={headers}
                                data={
                                    csvData.map((d, index) => ({
                                        pts: d.approvedPts && d.status === "Active" ?d.approvedPts :d.tuzoPoints >= 0 && d.status === "Active" ? d.tuzoPoints : !d.approvedPts && d.tuzoPoints && d.status === "Pending" ? d.tuzoPoints : "X",
                                        name: d.firstName + " " + d.lastName,
                                        companyName: d.companyName,
                                        email: d.email,
                                        status: d.status,
                                    }))
                                }
                                filename={`dan_miller_user_list`}

                            >
                                <CSVIcon style={{ marginRight: "10px" }} /> Export CSV

                            </CSVLink>
                        </Button> */}
                </GridItem>
            </GridContainer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Filter));
